import client from '../../../config/api';
import DIJVueConfigurations from '../../../helpers/DIJVueConfigurations';

import {
  deleteRecord,
  getDirtySyncRecords,
  getRecordUsingSync,
  updateRecord,
} from '../../../services/db';

import { ADD_SYNCHRONIZED_BUBBLE } from '../../bubbles/mutations/mutationTypes';

import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION,
} from '../../questions/actions/actionTypes';

const synchronizeJob = (syncRecord, job) => {
  switch (syncRecord.status) {
    case 'new':
      return client.post('job/', job);
    case 'existing':
      return client.put(`job/${job._id}`, job);
    case 'deleted':
      return client.delete(`job/${job._id}`);
    default:
      throw new Error(`Unsupported status '${syncRecord.status}'`);
  }
};

const synchronizeTask = (syncRecord, task) => {
  switch (syncRecord.status) {
    case 'new':
      return client.post('task/', task);
    case 'existing':
      return client.put(`/task/${task._id}`, task);
    case 'deleted':
      return client.delete(`/task/${task._id}`);
    default:
      throw new Error(`Unsupported status '${syncRecord.status}'`);
  }
};

const synchronizeBubble = async (syncRecord, bubble, commit) => {
  try {
    let response;
    switch (syncRecord.status) {
      case 'new':
        response = await client.post(`bubble/${bubble.taskId}`, bubble);
        commit(ADD_SYNCHRONIZED_BUBBLE, response.data, { root: true });
        return response;

      case 'existing':
        response = await client.put(`bubble/${bubble._id}`, bubble);
        commit(ADD_SYNCHRONIZED_BUBBLE, response.data, { root: true });
        return response;

      case 'deleted':
        return client.delete(`bubble/${bubble._id}`);

      default:
        throw new Error(`Unsupported status '${syncRecord.status}'`);
    }
  } catch (error) {
    console.error(`Failed to synchronize bubble (ID: ${bubble._id})`, error);
  }
};

const synchronizeQuestion = (syncRecord, question, dispatch) => {
  const actionMap = {
    new: {
      type: CREATE_QUESTION,
      payload: { taskId: question.taskId, question },
    },
    existing: {
      type: UPDATE_QUESTION,
      payload: question,
    },
    deleted: { type: DELETE_QUESTION, payload: question._id },
  };

  const actionEntry = actionMap[syncRecord.status];

  if (!actionEntry) {
    throw new Error(`Unsupported status '${syncRecord.status}'`);
  }

  return dispatch(actionEntry.type, actionEntry.payload, { root: true });
};

const synchronizeImage = async (syncRecord, image) => {
  const { base64data, fileName, path } = image;
  const dijConfigurations = new DIJVueConfigurations();

  let uploadPath = path.substring(0, path.lastIndexOf('/'));
  if (uploadPath.startsWith('/')) {
    uploadPath = uploadPath.substring(1);
  }
  const response = await fetch(base64data);
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: blob.type });

  return dijConfigurations.uploadFileToS3(file, fileName, uploadPath);
};

export default {
  async SYNCHRONIZE_LOCAL_DATABASE(
    { dispatch, commit },
    { toast, manualSync = false }
  ) {
    if (!toast) {
      console.error('Toast instance is not provided');
      return;
    }

    // Fetch all dirty records
    const dirtySyncRecords = await getDirtySyncRecords(manualSync);
    const totalRecords = dirtySyncRecords.length;

    if (totalRecords === 0) {
      console.log('Nothing to synchronize');
      return;
    }

    // Create an initial toast notification
    const syncToast = toast.open({
      duration: 50000, // Keep toast open during sync
      message: 'Preparing to synchronize...',
      type: 'is-warning',
      position: 'is-bottom',
    });

    let processedRecords = 0;

    // Function to update toast message
    const updateToastMessage = (successCount, failureCount) => {
      syncToast.message = `Sync Progress: ${processedRecords}/${totalRecords} | ✅ ${successCount} | ❌ ${failureCount}`;
    };

    // Map over records and create promises for all sync operations
    const syncPromises = dirtySyncRecords.map(async (dirtySyncRecord) => {
      try {
        const dataRecord = await getRecordUsingSync(dirtySyncRecord);

        if (!dataRecord) {
          console.warn(
            `Skipping sync for record (Type: ${dirtySyncRecord.recordType}, ID: ${dirtySyncRecord.id}) - No matching data found.`
          );
          return { status: 'skipped', record: dirtySyncRecord };
        }

        let syncPromise;
        switch (dirtySyncRecord.recordType) {
          case 'job':
            syncPromise = synchronizeJob(dirtySyncRecord, dataRecord);
            break;
          case 'task':
            syncPromise = synchronizeTask(dirtySyncRecord, dataRecord);
            break;
          case 'bubble':
            syncPromise = synchronizeBubble(
              dirtySyncRecord,
              dataRecord,
              commit
            );
            break;
          case 'question':
            syncPromise = synchronizeQuestion(
              dirtySyncRecord,
              dataRecord,
              dispatch
            );
            break;
          case 'image':
            syncPromise = synchronizeImage(
              dirtySyncRecord,
              dataRecord,
              dispatch
            );
            break;
          default:
            throw new Error(`Unsupported type '${dirtySyncRecord.recordType}'`);
        }

        await syncPromise; // Await the sync operation

        // After sync delete also the record in case of delete
        if (dirtySyncRecord.status === 'deleted') {
          deleteRecord(dirtySyncRecord.recordType, dataRecord._id);
          deleteRecord('sync', dirtySyncRecord._id);
        }

        let deleteCheckedJobId = false;
        if (dirtySyncRecord.checkedJobId && manualSync) {
          dirtySyncRecord.checkedJobId = null;
          deleteCheckedJobId = true;
        }

        // set isDirty back to false after successful sync and delete checkedJobId
        if (dirtySyncRecord.status !== 'deleted') {
          await updateRecord(
            dirtySyncRecord.recordType,
            dataRecord,
            null,
            deleteCheckedJobId
          );
        }

        return { status: 'fulfilled', record: dirtySyncRecord };
      } catch (error) {
        console.error(
          `Failed to sync record (Type: ${dirtySyncRecord.recordType}, ID: ${dirtySyncRecord.id})`,
          error
        );
        return { status: 'rejected', record: dirtySyncRecord };
      }
    });

    // Wait for all sync requests to complete
    const results = await Promise.allSettled(syncPromises);

    // Count success and failed requests
    const successCount = results.filter(
      (result) => result.status === 'fulfilled'
    ).length;
    const failureCount = results.filter(
      (result) => result.status === 'rejected'
    ).length;

    processedRecords = successCount + failureCount;
    updateToastMessage(successCount, failureCount);

    if (failureCount > 0) {
      syncToast.message = `Sync Completed: ${successCount} | ${failureCount} failed. Check console for details.`;
      syncToast.type = 'is-danger';
    } else {
      syncToast.message = `Synchronization Complete: ${successCount} records processed successfully.`;
      syncToast.type = 'is-success';
    }

    setTimeout(() => {
      syncToast.close();
    }, 3000);
  },
};
