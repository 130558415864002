<template>
  <div class="reference-image-wrapper" v-if="imagePreview">
    <div>
      <b-checkbox v-model="useImageReference"
        >Use image as task reference</b-checkbox
      >
    </div>

    <img :src="imagePreview" class="reference-image" />
    <b-button
      type="is-danger"
      icon-right="delete"
      class="delete-button"
      @click="deleteImage"
    />
  </div>
  <div
    class="reference-image-wrapper"
    v-else-if="instruction && instruction.imageReference"
  >
    <div>
      <b-checkbox v-model="useImageReference" @input="handleCkeckbox"
        >Use image as task reference</b-checkbox
      >
    </div>

    <img :src="getImage(instruction)" class="reference-image" />
    <b-button
      type="is-danger"
      icon-right="delete"
      class="delete-button"
      @click="deleteImage"
    />
  </div>
  <b-upload
    v-else
    v-model="file"
    class="file-label reference-image"
    rounded
    accept="image/*"
    @input="handleFileUpload"
  >
    <span class="file-cta">
      <b-icon class="file-icon" icon="upload"></b-icon>
      <span class="file-label"> Click to upload reference image </span>
    </span>
  </b-upload>
</template>

<script>
import { UPDATE_QUESTION } from '../../../../store/questions/actions/actionTypes';
import { GET_CURRENT_TASK } from '../../../../store/tasks/getters/getterTypes';

export default {
  name: 'ReferenceImage',

  data() {
    return {
      file: null,
      image: null,
      instruction: null,
      useImageReference: false,
      imagePreview: null,
    };
  },
  props: {
    instructionProps: {
      type: Object,
      default: null,
    },
  },

  async mounted() {
    const currentTask = await this.$store.dispatch('getTask', this.task._id);
    this.instruction = this.instructionProps;
    if (
      this.instruction &&
      currentTask.thumbnailUrl &&
      this.instruction.imageReference &&
      currentTask.thumbnailUrl === this.instruction.imageReference
    ) {
      this.useImageReference = true;
    } else {
      this.useImageReference = false;
    }
  },

  computed: {
    task() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    user() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    async handleCkeckbox(val) {
      if (this.instructionProps) {
        const currentTask = this.task;
        const thumbnailUrl = this.instruction.imageReference;
        if (val) {
          this.$store.dispatch('updateTask', { ...currentTask, thumbnailUrl });
        } else {
          this.$store.dispatch('updateTask', {
            ...currentTask,
            thumbnailUrl: null,
          });
        }
      }
    },

    async handleFileUpload(file) {
      if (file) {
        this.imagePreview = URL.createObjectURL(file);
      }
    },

    async deleteImage() {
      if (!this.instructionProps) {
        this.file = null;
        this.imagePreview = null;
        return;
      }
      await this.$store.dispatch(UPDATE_QUESTION, {
        imageReference: null,
        _id: this.instructionProps._id,
      });
      if (this.task.thumbnailUrl === this.instruction.imageReference) {
        const currentTask = this.task;
        this.$store.dispatch('updateTask', {
          ...currentTask,
          thumbnailUrl: null,
        });
      }
      this.instruction.imageReference = null;
    },

    getImage(instruction) {
      // For local env we need to point to the local s3
      if (
        instruction.imageReference &&
        window.location.host.includes('localhost')
      ) {
        return `http://localhost:4566/images${instruction.imageReference}`;
      }
      return instruction.imageReference;
    },
  },
};
</script>

<style scoped>
.reference-image {
  margin-top: 20px;
}

.reference-image-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;

  div {
    display: flex;
    padding-left: 40px;
  }

  img {
    height: 250px;
  }

  button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); /* Centers the button horizontally */
  }
}
</style>
