<template>
  <b-modal :active="visible" has-modal-card @close="$emit('cancel')">
    <div class="modal-card" style="width: auto; max-width: 960px">
      <header class="modal-card-head">
        <p class="modal-card-title">Version Conflicts Detected</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('cancel')"
        ></button>
      </header>

      <section class="modal-card-body">
        <section
          v-for="(entity, index) in uniqueConflicts"
          :key="entity._id"
          class="mb-6 mt-3"
        >
          <div class="media mb-3">
            <div class="media-content is-flex is-align-items-center">
              <p>
                <strong>Bubble ({{ entity.localEntity.number }}):</strong>
                {{ entity.localEntity.type }}
              </p>
            </div>
          </div>

          <div class="buttons are-small mb-4">
            <b-button size="is-small" @click="resolveAll(index, 'local')">
              Use Mine for All
            </b-button>
            <b-button size="is-small" @click="resolveAll(index, 'server')">
              Use Theirs for All
            </b-button>
          </div>

          <div
            v-for="(change, key) in changedFields(
              entity.localEntity,
              entity.serverEntity
            )"
            :key="key"
            class="box p-4 mb-4"
          >
            <div
              class="is-flex is-justify-content-space-between is-align-items-center mb-3"
              style="gap: 10px"
            >
              <label class="label">{{ fieldLabels[key] || key }}</label>
              <div class="buttons are-small">
                <b-button
                  type="is-success"
                  :outlined="resolution[index]?.[key] !== 'local'"
                  :disabled="key === 'version'"
                  @click="resolveField(index, key, 'local')"
                >
                  Keep Mine
                </b-button>
                <b-button
                  type="is-danger"
                  :outlined="resolution[index]?.[key] !== 'server'"
                  @click="resolveField(index, key, 'server')"
                >
                  Use Theirs
                </b-button>
              </div>
            </div>

            <div class="columns is-vcentered">
              <div class="column">
                <label class="label">Yours</label>
                <b-input
                  :value="String(formatValue(change.local))"
                  readonly
                  expanded
                  class="is-family-monospace"
                />
              </div>
              <div class="column">
                <label class="label">Theirs</label>
                <b-input
                  :value="String(formatValue(change.server))"
                  readonly
                  expanded
                  class="is-family-monospace"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      <footer class="modal-card-foot is-justify-content-flex-end">
        <b-button type="is-light" @click="$emit('cancel')">Cancel</b-button>
        <b-button
          type="is-primary"
          :disabled="!isAllResolved"
          @click="confirmResolution"
        >
          Confirm
        </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
const fieldLabels = {
  version: 'Version',
  point: 'Bubble position',
};

export default {
  name: 'ConflictDialog',
  props: {
    visible: Boolean,
    conflicts: Array,
  },
  data() {
    return {
      fieldLabels,
      resolution: {},
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) this.autoSelectVersionFields();
    },
  },
  computed: {
    uniqueConflicts() {
      const map = {};
      this.conflicts.forEach(({ localEntity, serverEntity }) => {
        const id = localEntity._id;
        if (!map[id]) {
          map[id] = {
            _id: id,
            localEntity: { ...localEntity },
            serverEntity: { ...serverEntity },
          };
        } else {
          Object.assign(
            map[id].localEntity,
            JSON.parse(JSON.stringify(localEntity))
          );
          Object.assign(
            map[id].serverEntity,
            JSON.parse(JSON.stringify(serverEntity))
          );
        }
      });
      return Object.values(map);
    },
    isAllResolved() {
      return this.uniqueConflicts.every((conflict, index) => {
        const changed = Object.keys(
          this.changedFields(conflict.localEntity, conflict.serverEntity)
        );
        return changed.every((key) => this.resolution[index]?.[key]);
      });
    },
  },
  methods: {
    autoSelectVersionFields() {
      this.uniqueConflicts.forEach((conflict, index) => {
        const changed = this.changedFields(
          conflict.localEntity,
          conflict.serverEntity
        );
        if (changed.version) {
          this.$set(this.resolution, index, {
            ...this.resolution[index],
            version: 'server',
          });
        }
      });
    },
    changedFields(local, server) {
      const diffs = {};
      Object.keys(local).forEach((key) => {
        const a = this.normalizeValue(local[key]);
        const b = this.normalizeValue(server[key]);
        if (!this.isDeepEqual(a, b)) {
          diffs[key] = { local: local[key], server: server[key] };
        }
      });
      return diffs;
    },
    normalizeValue(val) {
      if (val === undefined || val === null) return '';
      if (Array.isArray(val) && val.length === 0) return '';
      return val;
    },
    isDeepEqual(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    resolveField(index, key, version) {
      if (!this.resolution[index]) this.$set(this.resolution, index, {});
      if (key === 'version') {
        this.$set(this.resolution[index], key, 'server');
      } else {
        this.$set(this.resolution[index], key, version);
      }
    },
    resolveAll(index, version) {
      const entity = this.uniqueConflicts[index];
      const fields = this.changedFields(
        entity.localEntity,
        entity.serverEntity
      );
      if (!this.resolution[index]) this.$set(this.resolution, index, {});
      Object.keys(fields).forEach((key) => {
        if (key !== 'version') {
          this.$set(this.resolution[index], key, version);
        }
      });
    },
    formatValue(val) {
      return typeof val === 'object' && val !== null
        ? JSON.stringify(val, null, 2)
        : val;
    },
    confirmResolution() {
      const results = this.uniqueConflicts.map((conflict, index) => {
        const resolved = { ...conflict.localEntity };
        resolved.version = conflict.serverEntity.version;
        const decisions = this.resolution[index] || {};
        Object.keys(decisions).forEach((key) => {
          if (decisions[key] === 'server') {
            resolved[key] = conflict.serverEntity[key];
          }
        });
        return resolved;
      });
      this.$emit('resolved', results);
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
}
</style>
