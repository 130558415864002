export default {
  SET_QUESTIONS: (state, questions) => {
    state.questions = questions.sort((a, b) => {
      return parseInt(a.label, 10) - parseInt(b.label, 10);
    });
  },

  REMOVE_QUESTION: (state, questionId) => {
    state.questions = state.questions.filter(
      (question) => question._id !== questionId
    );
  },
  LOCAL_UPDATE_QUESTION: (state, updatedQuestion) => {
    const index = state.questions.findIndex(
      (question) => question._id === updatedQuestion._id
    );
    if (index !== -1) {
      state.questions.splice(index, 1, updatedQuestion);
    } else {
      state.questions.push(updatedQuestion);
    }
  },

  CHANGE_QUESTIONS_ORDER: (state, payload) => {
    const { question, targetQuestion } = payload;

    // Find the indices of the questions
    const questionIndex = state.questions.findIndex((q) => q._id === question);
    const targetIndex = state.questions.findIndex(
      (q) => q._id === targetQuestion
    );

    if (questionIndex === -1 || targetIndex === -1) {
      console.error('Invalid question or targetQuestion label');
      return;
    }

    // Reorder the questions array
    const reorderedQuestions = [...state.questions];
    const questionToMove = reorderedQuestions.splice(questionIndex, 1)[0];
    reorderedQuestions.splice(targetIndex, 0, questionToMove);

    // Update labels based on the new order
    const newLabels = reorderedQuestions.map((q, index) => ({
      ...q,
      label: index + 1,
    }));

    // Update state
    state.questions = newLabels;
  },
  QUESTIONS_ORDER: (state) => {
    // Reorder the questions array
    const reorderedQuestions = [...state.questions];
    // Update labels based on the new order
    const newLabels = reorderedQuestions.map((q, index) => ({
      ...q,
      label: index + 1,
    }));

    state.questions = newLabels;
  },
};
