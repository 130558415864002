import onlineService from './onlineService';

const baseURL = window.location.origin;
const cacheName = 'dijv1';
const warmupFlagURL = `${baseURL}/cache-warmed.json`;

const assetGroups = {
  homepageIcons: [
    'admin.png',
    'editor.png',
    'publisher.png',
    'inspector.png',
    'report.png',
  ],
  img: [
    'dij-tablet-portrait.png',
    'diagram_icon.png',
    'ic-assignment-black-24-px.png',
    'Instruction_icon.png',
    'siemens-energy-logo.png',
    'image-empty.png',
  ],
  icons: ['back_arrow.png', 'favicon.svg', 'android-chrome-192x192.png'],
  fonts: [
    'materialdesignicons-webfont.088756a8.woff2',
    'sisan06.cf68c634.ttf',
    'sisan03.bb7bc470.ttf',
  ],
  base: ['favicon.ico'],
  posImages: ['pos_1.png', 'pos_2.png', 'pos_3.png', 'pos_4.png'],
};

function buildURLs(groups) {
  return [
    ...(groups.homepageIcons?.map(
      (name) => `${baseURL}/img/icons/homepage/${name}`
    ) || []),
    ...(groups.icons?.map((name) => `${baseURL}/img/icons/${name}`) || []),
    ...(groups.fonts?.map((name) => `${baseURL}/fonts/${name}`) || []),
    ...(groups.img?.map((name) => `${baseURL}/img/${name}`) || []),
    ...(groups.posImages?.map(
      (name) => `${baseURL}/img/position-image/${name}`
    ) || []),
    ...(groups.base?.map((name) => `${baseURL}/${name}`) || []),
  ];
}

// eslint-disable-next-line import/prefer-default-export
export async function warmUpCache() {
  if (!('caches' in window)) return;

  const isOffline = !(await onlineService.getStatus());
  if (isOffline) {
    return;
  }

  try {
    const cache = await caches.open(cacheName);
    // eslint-disable-next-line no-unused-vars
    const warmFlag = await cache.match(warmupFlagURL);

    // if (warmFlag) {
    //   return;
    // }

    const urlsToWarmUp = buildURLs(assetGroups);
    const fetchAndCache = urlsToWarmUp.map(async (url) => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          await cache.put(url, response.clone());
        }
      } catch (err) {
        console.warn(`Could not warm up cache for ${url}`, err);
      }
    });

    await Promise.all(fetchAndCache);

    const markerResponse = new Response(JSON.stringify({ warmedUp: true }), {
      headers: { 'Content-Type': 'application/json' },
    });
    await cache.put(warmupFlagURL, markerResponse);
    console.log('Warmup of files completed');
  } catch (error) {
    console.error('Failed to warm up cache:', error);
  }
}
