import ChatBox from '../components/ChatBox';

const ChatBoxPlugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, opt) {
    const { store } = opt; // Extract store from options

    if (!store) {
      console.error('Vuex store is required for ChatBoxPlugin');
      return;
    }

    const ChatBoxConstructor = Vue.extend(ChatBox);

    const chatBoxInstance = new ChatBoxConstructor({
      store,
      propsData: {
        isVisible: false,
      },
    });

    chatBoxInstance.$mount();
    document.body.appendChild(chatBoxInstance.$el);

    Vue.prototype.$chatbox = {
      open(options = {}) {
        if (options.title) chatBoxInstance.title = options.title;
        if (options.message) chatBoxInstance.message = options.message;
        if (options.metadata) chatBoxInstance.metadata = options.metadata;

        chatBoxInstance.isVisible = true;
      },
      close() {
        chatBoxInstance.isVisible = false;
      },
      setMetadata(metadata) {
        chatBoxInstance.chatboxMetadata = metadata;
      },
    };

    chatBoxInstance.$on('close', () => {
      chatBoxInstance.isVisible = false;
    });
  },
};

export default ChatBoxPlugin;
