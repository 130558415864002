import axios from 'axios';

// eslint-disable-next-line no-unused-vars
const API_BASE_URL =
  'https://cfxrga9qz6.execute-api.eu-central-1.amazonaws.com/v1';

const API_BASE_UAT_URL =
  'https://024q7nk1l6.execute-api.eu-central-1.amazonaws.com/v1';

const REPAIR_SELECT_REQUEST_URL = `${API_BASE_UAT_URL}/items`;

// eslint-disable-next-line no-unused-vars
const API_HEADERS = {
  'Content-Type': 'application/json',
  'x-api-key': 'ekYI4qBTmn1KBoUkJvfTQagPS0iERDOG1tfNHbTL',
};

const API_UAT_HEADERS = {
  'Content-Type': 'application/json',
  'x-api-key': 'KbnKPFxhyv5znlpObgi9h10nUcI1J7nx1qdf4eN9',
};

export default {
  async SEND_REPAIR_SELECT_REQUEST(_, payload) {
    if (!payload) {
      console.warn(
        'sendRepairSelectRequest: No data provided for the request.'
      );
      return;
    }

    try {
      const response = await axios.post(REPAIR_SELECT_REQUEST_URL, payload, {
        headers: API_UAT_HEADERS,
      });
      return response.data;
    } catch (error) {
      console.error(
        'sendRepairSelectRequest: Request failed:',
        error.response?.data || error.message
      );
      throw new Error('Failed to send repair select request');
    }
  },
};
