<template>
  <div class="dij-ra-grid">
    <div v-if="isMinorComponents" class="dij-ra-header">
      <div class="dij-ra-text">{{ $t('assembly') }}</div>
      <div class="dij-ra-text">{{ $t('item_number') }}</div>
      <div class="dij-ra-text">{{ $t('part_name') }}</div>
      <div class="dij-ra-text">{{ $t('part_number') }}</div>
      <div class="dij-ra-number">{{ $t('quantity') }}</div>
      <div class="dij-ra-actions dij-ra-scrollbar-aware" v-if="!readOnly">
        {{ $t('actions') }}
      </div>
    </div>
    <div v-else class="dij-ra-header">
      <div class="dij-ra-number">{{ $t('level') }}</div>
      <div class="dij-ra-text">{{ $t('assembly') }}</div>
      <div class="dij-ra-number">{{ $t('item_number') }}</div>
      <div class="dij-ra-text">{{ $t('part_name') }}</div>
      <div class="dij-ra-text">{{ $t('part_number') }}</div>
      <div class="dij-ra-text">{{ $t('serialNumber') }}</div>
      <div class="dij-ra-number">{{ $t('quantity') }}</div>
      <div class="dij-ra-actions dij-ra-scrollbar-aware" v-if="!readOnly">
        {{ $t('actions') }}
      </div>
    </div>
    <DynamicScroller
      v-if="isMinorComponents"
      :items="repairAssessment"
      :min-item-size="57"
      class="scroller"
    >
      <template #after>
        <div class="dij-ra-empty" v-if="repairAssessment.length === 0">
          <span v-if="readOnly">{{
            $t('repair_assessment_empty_readonly')
          }}</span>
          <span v-else>{{ $t('repair_assessment_empty') }}</span>
        </div>
      </template>
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.partName]"
        >
          <template v-if="readOnly">
            <div
              class="dij-ra-data-row"
              @click="!isRowExpanded(item) && handleExpand(item, $event)"
            >
              <div class="dij-ra-text">{{ item.assembly }}</div>
              <div class="dij-ra-text">{{ item.itemNumber }}</div>
              <div class="dij-ra-text">{{ item.partName }}</div>
              <div class="dij-ra-text">{{ item.partNumber }}</div>
              <div class="dij-ra-number">{{ item.quantity }}</div>
            </div>
          </template>
          <template v-else>
            <div
              :title="
                isLockedByAnotherUser(item) ? getLockedInfoText(item) : ''
              "
              :class="[
                {
                  disabled: isLockedByAnotherUser(item),
                  'dij-ra-data-row': true,
                  'dij-ra-grabbable': moveMode,
                },
              ]"
              :id="item.id"
              :draggable="!isEditingValue && moveMode"
              @dragstart="handleRepairAssessmentDragStart"
              @drop="handleRepairAssessmentDragDrop"
              @dragenter="handleRepairAssessmentDragEnter"
              @dragover="$event.preventDefault()"
            >
              <div class="dij-ra-text">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  v-model="item.assembly"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  :controls="false"
                  v-model="item.itemNumber"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  v-model="item.partName"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="
                    tool.includes('editor') ||
                    isLockedByAnotherUser(item) ||
                    moveMode
                  "
                  v-model="item.partNumber"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-number">
                <b-numberinput
                  type="number"
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  :controls="false"
                  v-model="item.quantity"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-actions">
                <b-icon
                  icon="delete"
                  size="is-medium"
                  type="is-danger"
                  :class="
                    isLockedByAnotherUser(item) ||
                    item.isBeingDeleted ||
                    moveMode
                      ? 'disabled'
                      : ''
                  "
                  v-if="!readOnly"
                  @click.native="removeRow(item)"
                >
                </b-icon>
              </div>
            </div>
          </template>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <DynamicScroller
      v-else
      :items="repairAssessment"
      :min-item-size="57"
      class="scroller"
    >
      <template #after>
        <div class="dij-ra-empty" v-if="repairAssessment.length === 0">
          <span v-if="readOnly">{{
            $t('repair_assessment_empty_readonly')
          }}</span>
          <span v-else>{{ $t('repair_assessment_empty') }}</span>
        </div>
      </template>
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.partName]"
        >
          <template v-if="readOnly">
            <div
              class="dij-ra-data-row"
              @click="!isRowExpanded(item) && handleExpand(item, $event)"
            >
              <div class="dij-ra-number">{{ item.level }}</div>
              <div class="dij-ra-text">{{ item.assembly }}</div>
              <div class="dij-ra-number">{{ item.itemNumber }}</div>
              <div class="dij-ra-text">{{ item.partName }}</div>
              <div class="dij-ra-text">{{ item.partNumber }}</div>
              <div class="dij-ra-number">{{ item.serialNumber }}</div>
              <div class="dij-ra-number">{{ item.quantity }}</div>
            </div>
          </template>
          <template v-else>
            <div
              :title="
                isLockedByAnotherUser(item) ? getLockedInfoText(item) : ''
              "
              :class="[
                {
                  disabled: isLockedByAnotherUser(item),
                  'dij-ra-data-row': true,
                  'dij-ra-grabbable': moveMode,
                },
              ]"
              :id="item.id"
              :draggable="!isEditingValue && moveMode"
              @dragstart="handleRepairAssessmentDragStart"
              @drop="handleRepairAssessmentDragDrop"
              @dragenter="handleRepairAssessmentDragEnter"
              @dragover="$event.preventDefault()"
            >
              <div class="dij-ra-number">
                <b-numberinput
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  :controls="false"
                  v-model="item.level"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  v-model="item.assembly"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-number">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  :controls="false"
                  v-model="item.itemNumber"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  v-model="item.partName"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-text">
                <b-input
                  :disabled="
                    tool.includes('editor') ||
                    isLockedByAnotherUser(item) ||
                    moveMode
                  "
                  v-model="item.partNumber"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>

              <div class="dij-ra-text">
                <b-input
                  :disabled="
                    tool.includes('editor') ||
                    isLockedByAnotherUser(item) ||
                    moveMode
                  "
                  v-model="item.serialNumber"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-number">
                <b-numberinput
                  type="number"
                  :disabled="isLockedByAnotherUser(item) || moveMode"
                  :controls="false"
                  v-model="item.quantity"
                  @focus="handleInputFocus(item)"
                  @blur="handleInputBlur()"
                  @input="handleInputInput(item)"
                />
              </div>
              <div class="dij-ra-actions">
                <b-icon
                  icon="delete"
                  size="is-medium"
                  type="is-danger"
                  :class="
                    isLockedByAnotherUser(item) ||
                    item.isBeingDeleted ||
                    moveMode
                      ? 'disabled'
                      : ''
                  "
                  v-if="!readOnly"
                  @click.native="removeRow(item)"
                >
                </b-icon>
              </div>
            </div>
          </template>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>
<script>
import { v4 } from 'uuid';
import Vue from 'vue';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import debounce from 'lodash/debounce';
import onlineService from '../../services/onlineService';

Vue.component('RecycleScroller', RecycleScroller);

const uuidv4 = v4;

export default {
  name: 'repair-assessment',
  props: ['value', 'readOnly', 'tool', 'moveMode', 'isMinorComponents'],
  emits: ['input'],
  data() {
    return {
      repairAssessmentChanged: false,
      repairAssessmentMoveId: null,
      isEditingValue: false,
      updateRowAfterIntervalFunctions: {},
    };
  },
  computed: {
    repairAssessment: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    job() {
      return this.$store.state.currentJob;
    },
    user() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    const scrollbarElement = document.querySelector('.vue-recycle-scroller');

    new ResizeObserver(() => {
      const element = document.querySelector('.dij-ra-scrollbar-aware');
      if (!element) return;
      const scrollbarWidth =
        scrollbarElement.offsetWidth - scrollbarElement.clientWidth;
      if (scrollbarWidth > 0) {
        element.style.marginRight = `${scrollbarWidth}px`;
      } else {
        element.style.marginRight = 'unset';
      }
    }).observe(scrollbarElement);
  },
  methods: {
    async refresh() {
      this.job.repairAssessment = await this.$store.dispatch(
        'getRepairAssessment',
        this.job
      );
    },
    async handleInputFocus(row) {
      this.isEditingValue = true;

      if (!(await onlineService.getStatus())) {
        return;
      }

      if (!this.isLockedByCurrentUser(row)) {
        this.$store
          .dispatch('lockRepairAssessmentRow', {
            job: this.job,
            row,
            tool: this.tool,
          })
          .then((lockedRow) => {
            // Copy the lock fields
            row.lockedBy = lockedRow.lockedBy;
            row.lockedAt = lockedRow.lockedAt;
            row.changeLog = lockedRow.changeLog;

            let changed = false;
            const keys = Object.keys(lockedRow);
            keys.forEach((key) => {
              if (row[key] && lockedRow[key] && row[key] !== lockedRow[key]) {
                row[key] = lockedRow[key];
                changed = true;
              }
            });

            if (changed) {
              const username = row.changeLog[row.changeLog.length - 1].by;
              this.$buefy.toast.open({
                duration: 5000,
                message: `${username} changed this row and we loaded the new data`,
                type: 'is-warning',
              });
              this.$buefy.toast.open(
                `${username} changed the row a while back`
              );
            }
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.response.data.message,
              type: 'is-danger',
            });
            // Mark the selected line as locked
            row.lockedBy = 'unknown';
            row.lockedAt = this.getCurrentEpoch();
          });
      }
    },
    handleInputBlur() {
      this.isEditingValue = false;
    },
    handleInputInput(row) {
      this.$store.dispatch('setWaitingForWrite', true);
      if (!this.updateRowAfterIntervalFunctions[row.id]) {
        this.updateRowAfterIntervalFunctions[row.id] = debounce(
          this.updateRow,
          2000
        );
      }

      this.updateRowAfterIntervalFunctions[row.id](row);
    },
    handleRepairAssessmentDragStart(event) {
      this.repairAssessmentMoveId = event.target.id;
      event.dataTransfer.effectAllowed = 'move';
    },
    handleRepairAssessmentDragEnter(event) {
      event.dataTransfer.dropEffect = 'move';
      document.querySelectorAll('div.dij-ra-data-row').forEach((item) => {
        item.classList.remove('dij-ra-drag-selected');
      });
      event.target
        .closest('div.dij-ra-data-row')
        .classList.add('dij-ra-drag-selected');
      event.preventDefault();
    },
    handleRepairAssessmentDragDrop(event) {
      event.target
        .closest('div.dij-ra-data-row')
        .classList.remove('dij-ra-drag-selected');

      const endId = event.target.closest('div.dij-ra-data-row').id;
      if (this.repairAssessmentMoveId !== endId) {
        const startIndex = this.repairAssessment.findIndex(
          (element) => element.id === this.repairAssessmentMoveId
        );

        const endIndex = this.repairAssessment.findIndex(
          (element) => element.id === endId
        );

        this.$store
          .dispatch('moveRepairAssessmentRow', {
            job: this.job,
            firstRow: this.repairAssessment[startIndex],
            secondRow: this.repairAssessment[endIndex],
            tool: this.tool,
          })
          .then(() => {
            const startElement = this.repairAssessment[startIndex];
            this.repairAssessment.splice(startIndex, 1);
            this.repairAssessment.splice(endIndex, 0, startElement);

            this.repairAssessment = [...this.repairAssessment];
          });
      }
    },
    async addRow(activeTab) {
      if (activeTab === 0) {
        const newRow = {
          id: uuidv4(),
          level: 1,
          assembly: '',
          itemNumber: '',
          partName: '',
          partNumber: '',
          serialNumber: '',
          quantity: 1,
        };

        await this.$store.dispatch('addRepairAssessmentRow', {
          job: this.job,
          rows: newRow,
          tool: this.tool,
        });
      }

      if (activeTab === 1) {
        const newRow = {
          id: uuidv4(),
          isMinor: true,
          assembly: '',
          itemNumber: '',
          partName: '',
          partNumber: '',
          quantity: 1,
        };

        await this.$store.dispatch('addRepairAssessmentRow', {
          job: this.job,
          rows: newRow,
          tool: this.tool,
        });
      }
      this.refresh();
    },
    updateRow(row) {
      this.$store
        .dispatch('updateRepairAssessmentRow', {
          job: this.job,
          row,
          tool: this.tool,
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.response.data.message,
            type: 'is-danger',
          });
          // Mark the selected line as locked
          row.lockedBy = 'unknown';
          row.lockedAt = this.getCurrentEpoch();
        });
    },
    removeRow(row) {
      if (this.isLockedByCurrentUser(row)) {
        row.isBeingDeleted = true;
        this.$store
          .dispatch('deleteRepairAssessmentRow', {
            job: this.job,
            row,
            tool: this.tool,
          })
          .then(() => {
            this.repairAssessment = this.repairAssessment.filter(
              (element) => element !== row
            );
            this.refresh();
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.response.data.message,
              type: 'is-danger',
            });
            // Mark the selected line as locked
            row.lockedBy = 'unknown';
            row.lockedAt = this.getCurrentEpoch();
            delete row.isBeingDeleted;
          });
      } else {
        row.isBeingDeleted = true;
        this.$store
          .dispatch('lockRepairAssessmentRow', {
            job: this.job,
            row,
            tool: this.tool,
          })
          .then(() => {
            this.$store
              .dispatch('deleteRepairAssessmentRow', {
                job: this.job,
                row,
                tool: this.tool,
              })
              .then(() => {
                this.repairAssessment = this.repairAssessment.filter(
                  (element) => element !== row
                );
                this.refresh();
              })
              .catch((error) => {
                this.$buefy.toast.open({
                  duration: 5000,
                  message: 'Something went wrong. Please try again later.',
                  type: 'is-danger',
                });
                console.error(error);

                // Mark the selected line as locked
                row.lockedBy = 'unknown';
                row.lockedAt = this.getCurrentEpoch();
                delete row.isBeingDeleted;
              });
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Something went wrong. Please try again later.',
              type: 'is-danger',
            });
            console.error(error);
            // Mark the selected line as locked
            row.lockedBy = 'unknown';
            row.lockedAt = this.getCurrentEpoch();
            delete row.isBeingDeleted;
          });
      }
    },
    isLockedByAnotherUser(row) {
      const lockDecayTime = 60 * 60;
      return (
        !!row.lockedBy &&
        row.lockedBy !== this.user._id &&
        row.lockedBy !== this.user.id &&
        row.lockedAt + lockDecayTime > this.getCurrentEpoch()
      );
    },
    isLockedByCurrentUser(row) {
      const lockDecayTime = 60 * 60;
      return (
        !!row.lockedBy &&
        row.lockedBy === this.user._id &&
        row.lockedAt + lockDecayTime > this.getCurrentEpoch()
      );
    },
    getCurrentEpoch() {
      const nowDate = new Date();
      // Remove the timezone/DST offset to get UTC and convert to seconds
      const nowEpoch = Math.round(
        (nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000) / 1000
      );
      return nowEpoch;
    },
    getLockedInfoText(row) {
      return this.$t('repair_assessment_row_locked', {
        user: row.lockedBy,
      });
    },
    async addRATData(newRows) {
      await this.$store.dispatch('addRepairAssessmentRow', {
        job: this.job,
        rows: newRows,
        tool: this.tool,
      });

      this.repairAssessment = [...this.repairAssessment, ...newRows];
      this.$emit('input', newRows);
    },
  },
};
</script>

<style lang="scss" scoped>
div.dij-ra-empty {
  display: flex;
  justify-content: center;
  margin: 20px;
}
div.vue-recycle-scroller.scroller {
  height: 100%;
}
div.dij-annotator-modal > ::v-deep div.animation-content.modal-content {
  width: unset;
}
div.dij-ra-grid {
  height: 100%;
  display: flex;
  flex-direction: column;

  div.dij-ra-header > div,
  div.dij-ra-data-row > div {
    padding: 10px;

    &.dij-ra-number {
      min-width: 85px;
      flex: 0 0 0;
    }

    &.dij-ra-text {
      min-width: 120px;
      flex: 1 0 0;
    }
  }

  div.dij-ra-header {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid lightgray;

    div.dij-ra-actions {
      text-align: center;
    }
  }

  div.dij-ra-data-row {
    display: flex;
    border-bottom: 1px solid lightgray;

    &.disabled {
      background-color: rgb(240, 240, 240);
    }

    &.dij-ra-grabbable {
      cursor: grab;

      ::v-deep input[disabled] {
        cursor: grab;
      }
    }

    &.dij-ra-drag-selected {
      background-color: lightgrey;
    }

    div {
      &.dij-ra-actions {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          cursor: pointer;

          &.disabled {
            cursor: unset;
            pointer-events: none;
            color: blue;
            ::v-deep i {
              color: lightgray;
            }
          }
        }
      }
    }
  }
}
</style>
