export default {
  drawImageFor1920px(canvas, image) {
    const context = canvas.getContext('2d');

    // Set canvas size to match target resolution
    const targetWidth = 1920;
    const targetHeight = 1920;
    const ratio = image.width / image.height;
    if (image.width >= targetWidth || image.height >= targetHeight) {
      if (image.width > image.height) {
        if (targetWidth / ratio > targetHeight) {
          canvas.width = targetHeight * ratio;
          canvas.height = targetHeight;
        } else {
          canvas.width = targetWidth;
          canvas.height = targetWidth / ratio;
        }
      } else if (targetHeight * ratio > targetWidth) {
        canvas.width = targetWidth;
        canvas.height = targetWidth / ratio;
      } else {
        canvas.width = targetHeight * ratio;
        canvas.height = targetHeight;
      }
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }

    // Set canvas style to match size of screen
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight - 50;
    if (canvas.width > windowWidth || canvas.height > windowHeight) {
      if (image.width > image.height) {
        if (windowWidth / ratio > windowHeight) {
          canvas.style.width = `${windowHeight * ratio}px`;
          canvas.style.height = `${windowHeight} + 'px`;
        } else {
          canvas.style.width = `${windowWidth}px`;
          canvas.style.height = `${windowWidth / ratio}px`;
        }
      } else if (windowHeight * ratio > windowWidth) {
        canvas.style.width = `${windowWidth}px`;
        canvas.style.height = `${windowWidth / ratio}px`;
      } else {
        canvas.style.width = `${windowHeight * ratio}px`;
        canvas.style.height = `${windowHeight}px`;
      }
    } else {
      canvas.style.width = `${canvas.width}px`;
      canvas.style.height = `${canvas.height}px`;
    }

    // Center image
    const canvasStyleWidth = parseInt(canvas.style.width.split('px'), 10);
    if (canvasStyleWidth < windowWidth) {
      canvas.style.marginLeft = `${(windowWidth - canvasStyleWidth) / 2}px`;
    }
    const canvasStyleHeight = parseInt(canvas.style.height.split('px'), 10);
    if (canvasStyleHeight < windowHeight) {
      canvas.style.marginTop = `${(windowHeight - canvasStyleHeight) / 2}px`;
    }

    // Finally draw image using canvas full size
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
  },
};
