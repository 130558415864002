import PublisherAddOrder from '../components/Publisher/AddOrder';
import PublisherEditOrder from '../components/Publisher/AddOrderInfoPage';
import PublisherCreateTask from '../components/Publisher/AddTask';
import PublisherCreatePart from '../components/Publisher/ConfigurePart';
import PublisherCopyFrom from '../components/Publisher/CopyFrom';
import PublisherList from '../components/Publisher/List';
import PublisherTasks from '../components/Publisher/Tasks';
import PublisherTaskView from '../modules/Publisher/views/TaskView';

const publisherRoutes = [
  {
    path: '/view/publisher',
    name: 'publisher-list',
    component: PublisherList,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/create',
    name: 'publisher-add-order',
    component: PublisherAddOrder,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/:id/edit',
    name: 'publisher-edit-order',
    component: PublisherEditOrder,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/:id/copyfrom',
    name: 'publisher-copyfrom',
    component: PublisherCopyFrom,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/:id',
    name: 'publisher-tasks',
    component: PublisherTasks,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/:id/:taskNumber',
    name: 'publisher-template',
    component: PublisherTaskView,
    meta: { authRequired: true, needsTaskLock: true },
  },
  {
    path: '/view/publisher/:id/create',
    name: 'publisher-create-task',
    component: PublisherCreateTask,
    meta: { authRequired: true },
  },
  {
    path: '/view/publisher/:id/create/part',
    name: 'publisher-create-part',
    component: PublisherCreatePart,
    meta: { authRequired: true },
  },
];

export default publisherRoutes;
