/* eslint-disable no-else-return */
import BubbleDTO from '../shared/dtos/BubbleDTO';
import {
  createRecord,
  getRecord,
  getRecordsUsingIndex,
  updateRecord,
} from '../services/db';
import { baseMarkForDelete, extractUrlSegment, matchRoute } from './base';

const clearBubbleMeasurements = (bubble) => {
  bubble.inspectorComment = '';
  bubble.images = [];
  bubble.lastModifiedAt = '';
  bubble.lastModifiedBy = '';
  bubble.measurements.forEach((measurment) => {
    if (bubble.type === 'visual') {
      measurment.value = 'notCompleted';
    } else {
      measurment.value = '';
    }
    measurment.images = [];
    measurment.comment = '';
  });
  return bubble;
};

const postHandlers = {
  'POST bubbles/:taskId': async ({ taskId }, body) => {
    const data = body;
    const user = await getRecord('session', 'user');

    const bubbleCreatePromises = [];
    data.forEach(async (inspection) => {
      const bubbleToCreate = new BubbleDTO({
        taskId,
        ...inspection,
      });

      if (inspection.isInspector) {
        bubbleToCreate.lastModifiedBy = user.fullname;
        bubbleToCreate.lastModifiedAt = new Date().toISOString();
        delete bubbleToCreate.isInspector;
      }

      bubbleCreatePromises.push(
        createRecord(
          'bubble',
          bubbleToCreate,
          false,
          bubbleToCreate.checkedJobId
        )
      );
    });

    await Promise.all(bubbleCreatePromises);

    return 'created';
  },
  'POST bubble/:taskId': async ({ taskId }, body) => {
    const bubble = body;

    const bubbleData = new BubbleDTO({
      taskId,
      ...bubble,
    });

    await createRecord('bubble', bubbleData, false, body.checkedJobId);

    return bubbleData;
  },
};

const handlers = {
  ...postHandlers,
};

const offlineGet = async (...args) => {
  const url = args[0];
  const taskId = extractUrlSegment(url, 1);
  const firstSegment = extractUrlSegment(url, 0);
  if (firstSegment === 'bubbles' && taskId) {
    return getRecordsUsingIndex('bubble', 'taskId', taskId);
  }

  throw new Error(`Failed to handle ${url} with method GET`);
};

const offlinePost = async (url, body, ...args) => {
  const { handler, params, url: fullUrl } = matchRoute('POST', url, handlers);
  return handler(params, body, fullUrl, args);
};

const offlinePut = async (...args) => {
  const url = args[0];
  const body = args[1];
  const bubbleId = extractUrlSegment(url, 1);
  const firstSegment = extractUrlSegment(url, 0);
  if (firstSegment === 'bubble' && bubbleId) {
    const data = body;

    const bubbleData = new BubbleDTO({ ...data, _id: bubbleId });

    await updateRecord('bubble', bubbleData);

    return bubbleData;
  }

  throw new Error(`Failed to handle ${url} with method PUT`);
};

const offlineDelete = async (...args) => {
  const url = args[0];
  const bubbleId = extractUrlSegment(url, 1);
  const firstSegment = extractUrlSegment(url, 0);
  if (firstSegment === 'bubble' && bubbleId) {
    return baseMarkForDelete(...args);
  }

  throw new Error(`Failed to handle ${url} with method DELETE`);
};

export {
  clearBubbleMeasurements,
  offlineGet,
  offlinePost,
  offlinePut,
  offlineDelete,
};
