import client from '../config/api';
import {
  cacheRecord,
  removeCheckedJobIdFromSyncRecords,
  getRecordUsingSync,
  getRecordsUsingIndex,
  deleteRecord,
} from '../services/db';
import { SYNCHRONIZE_LOCAL_DATABASE } from '../store/offline/actions/actionTypes';
import onlineService from '../services/onlineService';
import { GET_IS_OFFLINE_STATUS } from '../store/offline/getters/getterTypes';

export default {
  computed: {
    currentJob() {
      return this.$store.state.currentJob;
    },

    isJobCheckedOut() {
      return (
        this.currentJob.checkedOut === this.user._id ||
        this.currentJob.checkedOut === this.user.id
      );
    },
    computedIsOffline() {
      return this.$store.getters[GET_IS_OFFLINE_STATUS];
    },
  },
  methods: {
    async isOfflineMode() {
      const staus = await onlineService.getStatus();
      return !staus;
    },

    async preventOfflineAction() {
      if (!(await onlineService.getStatus())) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'This operation cannot be done in offline mode',
          type: 'is-warning',
          position: 'is-top',
        });
        return true;
      }
      return false;
    },
    async saveJobOffline(jobId) {
      try {
        this.isLoading = true;
        if (jobId) {
          await this.checkoutJobOffline(jobId);
        } else {
          await this.checkoutJobOffline();
        }

        // Get an cache latest version of the job
        let id;
        if (!jobId) {
          id = this.$route.params.id;
        } else {
          id = jobId;
        }

        const { data: job } = await client.get(`/job/${id}`);
        await cacheRecord('job', job, job._id);

        const { data: rootTasks } = await client.get(`/task/job/${id}`);
        const tasks = await this.processFolder(rootTasks);

        const promises = tasks.map(async (task) => {
          if (task.taskType === 'diagram') {
            const result = await client.get(`/bubbles/${task._id}`);

            const basedata = await this.imageUrlToBase64(task.diagram.src);
            cacheRecord(
              'image',
              {
                _id: task._id,
                base64data: basedata,
                recordType: 'diagram',
                recordId: task._id,
                fileName: 'diagram-image',
                path: task.diagram.src,
              },
              task.jobId
            );

            result.data.forEach((bubble) => {
              cacheRecord('bubble', bubble, task.jobId);

              if (bubble.images && bubble.images.length > 0) {
                bubble.images.forEach((image) => {
                  this.imageUrlToBase64(image.url).then((base64data) => {
                    cacheRecord(
                      'image',
                      {
                        _id: image.url || image.src,
                        base64data,
                        recordType: 'bubble',
                        recordId: bubble._id,
                        fileName: image.name,
                        path: image.url || image.src,
                      },
                      task.jobId
                    );
                  });
                });
              }
            });
          }

          if (task.taskType === 'instruction') {
            const result = await client.get(`/questions/${task._id}`);
            result.data.forEach((question) => {
              cacheRecord('question', question, task.jobId);

              if (question.image && question.image.length > 0) {
                question.image.forEach((image) => {
                  this.imageUrlToBase64(image.path).then((base64data) => {
                    cacheRecord(
                      'image',
                      {
                        _id: image.path,
                        base64data,
                        recordType: 'question',
                        recordId: question._id,
                        fileName: image.name,
                        path: image.path,
                      },
                      task.jobId
                    );
                  });
                });
              }
            });
          }

          await cacheRecord('task', task, job._id);
        });
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.$router.go(0);
      }
    },

    async checkoutJobOffline(jobId) {
      let id;
      if (!jobId) {
        id = this.$route.params.id;
      } else {
        id = jobId;
      }
      await client.patch(`/job/offline-checkout/${id}`);
      await this.$store.dispatch('getJob', id);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Job was saved successfully!',
        type: 'is-success',
        position: 'is-bottom-left',
      });
      console.log('Checkout job offline');
    },

    async processFolder(tasksToProcess) {
      const tasks = [];
      const processSubFolders = [];
      tasksToProcess.forEach((task) => {
        tasks.push(task);
        if (task.taskType === 'folder') {
          processSubFolders.push(
            client
              .get(`/task/job/${task.jobId}?folderId=${task._id}`)
              .then(({ data: folderTasks }) =>
                this.processFolder(folderTasks, client)
              )
          );
        }
      });

      await Promise.all(processSubFolders).then((subfoldersTasks) => {
        subfoldersTasks.forEach((subfolderTasks) => {
          tasks.push(...subfolderTasks);
        });
      });

      return tasks;
    },

    async checkinJobOffline(jobId) {
      try {
        this.isLoading = true;
        let id;
        if (!jobId) {
          id = this.$route.params.id;
        } else {
          id = jobId;
        }

        await client.patch(`/job/offline-checkin/${id}`);

        await this.$store.dispatch(SYNCHRONIZE_LOCAL_DATABASE, {
          manualSync: true,
          toast: this.$buefy.toast,
        });

        await removeCheckedJobIdFromSyncRecords(id);

        await this.$store.dispatch('getJob', id);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.$router.go(0);
      }
    },

    async imageUrlToBase64(urlImage) {
      let url = urlImage;

      const isAbsolute = /^https?:\/\//i.test(urlImage);
      if (!isAbsolute) {
        if (window.location.host.includes('localhost')) {
          url = `http://localhost:4566/images${urlImage}`;
        } else {
          url = `https://dij.mosaic.siemens-energy.cloud${urlImage}`;
        }
      }

      console.log(`Downloading image from: ${url}`);
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((onSuccess, onError) => {
          try {
            const reader = new FileReader();
            reader.onload = function () {
              onSuccess(this.result);
            };
            reader.readAsDataURL(blob);
          } catch (e) {
            onError(e);
          }
        });
      } catch (error) {
        console.error(
          `Something went wrong converting imageUrlToBase64 for ${urlImage}`,
          error
        );
      }
    },

    // Cache all job data in the local database
    async cacheJobData() {
      const availableMemorySpace = await this.getAvailableMemorySpace();
      const { id } = this.$route.params;
      const { jobMemorySize } = await client.get(`/jobsize/${id}`);

      if (
        availableMemorySpace.availableSpace + jobMemorySize >
        availableMemorySpace.quota
      ) {
        while (
          availableMemorySpace.availableSpace + jobMemorySize >
          availableMemorySpace.quota
        ) {
          // eslint-disable-next-line no-await-in-loop
          await this.removeOldJobs();
        }

        console.log('maximum memory reach');
        throw new Error('Can not cache job data, maximum memory reach');
      }

      try {
        const { data: job } = await client.get(`/job/${id}`);
        await cacheRecord('job', job);

        const { data: rootTasks } = await client.get(`/task/job/${id}`);
        const tasks = await this.processFolder(rootTasks);

        // const promises = tasks.map(async (task) => {
        tasks.map(async (task) => {
          if (task.taskType === 'diagram') {
            const result = await client.get(`/bubbles/${task._id}`);
            result.data.forEach(async (bubble) => {
              await cacheRecord('bubble', bubble);

              if (bubble.images && bubble.images.length > 0) {
                bubble.images.forEach((image) => {
                  this.imageUrlToBase64(image.url).then(async (base64data) => {
                    await cacheRecord('image', {
                      _id: image.url,
                      base64data,
                      recordType: 'bubble',
                      recordId: bubble._id,
                      fileName: image.name,
                      path: image.url,
                    });
                  });
                });
              }
            });
          }

          if (task.taskType === 'instruction') {
            const result = await client.get(`/questions/${task._id}`);
            result.data.forEach(async (question) => {
              await cacheRecord('question', question);

              if (question.image && question.image.length > 0) {
                question.image.forEach((image) => {
                  this.imageUrlToBase64(image.path).then(async (base64data) => {
                    await cacheRecord('image', {
                      _id: image.path,
                      base64data,
                      recordType: 'question',
                      recordId: question._id,
                      fileName: image.name,
                      path: image.path,
                    });
                  });
                });
              }
            });
          }

          await cacheRecord('task', task);
        });
        // await Promise.all(promises);
      } catch (error) {
        console.error(error);
        throw new Error('Error caching job data');
      }
    },

    async getAvailableMemorySpace() {
      if (!navigator.storage || !navigator.storage.estimate) {
        console.warn('StorageManager API is not supported in this browser.');
        return null;
      }

      try {
        const { quota, usage } = await navigator.storage.estimate();
        const availableSpace = quota - usage;
        console.log(
          `Quota: ${quota}, Usage: ${usage}, Available: ${availableSpace}`
        );
        return {
          quota,
          usage,
          availableSpace,
        };
      } catch (error) {
        console.error('Error estimating available storage space:', error);
        return null;
      }
    },

    async removeOldJobs() {
      const savedJobs = await getRecordUsingSync('job');
      const oldestJob = savedJobs.reduce(
        (oldest, job) => (!oldest || job.date < oldest.date ? job : oldest),
        null
      );

      const jobTasks = await getRecordsUsingIndex(
        'task',
        'jobId',
        oldestJob._id
      );

      const bubblesPromises = jobTasks.map(async (task) => {
        const bubbles = await getRecordsUsingIndex(
          'bubble',
          'jobId',
          task.jobId
        );
        bubbles.forEach(async (bubble) => {
          await deleteRecord('bubble', bubble._id); // Remove bubble from cache
        });
      });

      const questionsPromises = jobTasks.map(async (task) => {
        const questions = await getRecordsUsingIndex(
          'question',
          'jobId',
          task.jobId
        );
        questions.forEach(async (question) => {
          await deleteRecord('question', question._id); // Remove question from cache
        });
      });

      await Promise.all([...bubblesPromises, ...questionsPromises]);

      // Remove tasks and job from cache
      jobTasks.forEach(async (task) => {
        await deleteRecord('task', task._id);
      });

      await deleteRecord('job', oldestJob._id);
    },
  },
};
