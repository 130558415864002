import jwt_decode from 'jwt-decode';
import Vue from 'vue';
import Router from 'vue-router';

// Routes
import adminRoutes from './routes/admin';
import editorRoutes from './routes/editor';
import folderRoutes from './routes/folder';
import inspectorRoutes from './routes/inspector';
import publisherRoutes from './routes/publisher';
import reportRoutes from './routes/report';

// Single components
import NotFound from './components/layout/404';
import Callback from './components/layout/Callback';
import Dashboard from './components/layout/Dashboard';
import FleetData from './components/layout/FleetData';
import Login from './components/layout/Login';
import Logout from './components/layout/Logout';
import Profile from './components/layout/Profile';
import Version from './components/layout/Version';
import NewUserWelcomePage from './components/layout/NewUserWelcomePage';

import LinkRedirect from './components/layout/LinkRedirect';

import ImageUpload from './components/ImageUpload/Index';

import APIDocs from './views/api-docs';

import store from './store';

import { acquireToken } from './services/auth';
import { USERS_GET_CURRENT } from './store/users/actionTypes';
import onlineService from './services/onlineService';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...editorRoutes,
    ...publisherRoutes,
    ...inspectorRoutes,
    ...reportRoutes,
    ...adminRoutes,
    ...folderRoutes,

    {
      path: '/view/profile',
      name: 'my-profile',
      component: Profile,
      meta: { authRequired: true },
    },
    {
      path: '/view/fleetdata',
      name: 'fleet-data',
      component: FleetData,
      meta: { authRequired: true },
    },
    {
      path: '/view/404',
      name: '404',
      component: NotFound,
    },
    // { path: '*', component: NotFound },
    {
      path: '/new-user-welcome-page',
      component: NewUserWelcomePage,
      meta: { authRequired: true },
    },
    { path: '/signin', component: Login },
    { path: '/logout', name: 'logout', component: Logout },
    {
      path: '/view/dashboard',
      alias: '/',
      component: Dashboard,
      meta: { authRequired: true },
    },
    {
      path: '/view/version',
      component: Version,
      meta: { authRequired: true },
    },
    { path: '/callback', name: 'callback-route', component: Callback },

    { path: '/view/image/upload', component: ImageUpload },
    {
      path: '/api/v1/link/:id',
      component: LinkRedirect,
      meta: { authRequired: true },
    },

    { path: '/view/api-docs', component: APIDocs },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Early return if offline
  if (!(await onlineService.getStatus())) {
    const user = await store.dispatch(USERS_GET_CURRENT);
    if (user.roles.includes('editor') || user.roles.includes('admin')) {
      user.roles = user.roles.filter(
        (role) => role !== 'editor' && role !== 'admin'
      );
    }

    store.commit('SET_CURRENT_USER_ALLOWED_TOOLS', user.roles);

    const isPendingOrRejected = user.isPendingApproval || user.isRejected;
    if (!isPendingOrRejected && to.fullPath === '/new-user-welcome-page') {
      return next('/view/dashboard');
    }

    // Ensure the user has permission for the route's tool
    const pathSegments = to.path.split('/');
    const isAccessingTool = pathSegments.length > 2;

    if (isAccessingTool) {
      const tool = pathSegments[2];
      const defaultTools = ['dashboard', 'profile', 'version', 'fleetdata'];

      const hasPermission =
        defaultTools.includes(tool) ||
        user.roles.some((allowed) => allowed === tool);

      if (!hasPermission) {
        console.warn('No Permissions');
        return next('/404');
      }
    }

    return next();
  }

  // Check if the route requires authentication
  const requiresAuth = to.matched.some((r) => r.meta.authRequired);
  // Check if we need to refresh token (e.g., polymer endpoints)
  const shouldRefreshToken = to.matched.some((r) => r.meta.refreshToken);

  if (!requiresAuth) {
    return next();
  }

  // Attempt to acquire a token
  let accessToken;
  try {
    accessToken = await acquireToken(shouldRefreshToken);
  } catch (error) {
    accessToken = null;
  }

  // Validate acquired token
  let isTokenValid = false;
  if (accessToken) {
    const decoded = jwt_decode(accessToken);
    // Check if expiration time is still in the future
    isTokenValid = decoded.exp * 1000 > Date.now();
  }

  // If token invalid, redirect to signin
  if (!isTokenValid) {
    localStorage.setItem('path', to.path);
    return next({ path: '/signin' });
  }

  // Token is valid, ensure currentUser data is loaded
  if (!store.state.currentUser) {
    let isNewUser = false;
    await store.dispatch('getCurrentUserProfile').catch(() => {
      isNewUser = true;
    });
    // If we failed to load user, treat as new user -> redirect to signin
    if (isNewUser) {
      localStorage.setItem('path', to.path);
      return next({ path: '/signin' });
    }
  }

  const { currentUser } = store.state;

  // Special checks for OIL user
  const isOilUser = currentUser._id === process.env.VUE_APP_OIL_USER_EMAIL;
  const hasLinkParam = to.path.includes('link') || from.path.includes('link');
  const hasTokenParam =
    to.query.token &&
    to.query.token !== '' &&
    from.query.token &&
    from.query.token !== '';

  // OIL user must have link or token, otherwise logout
  if (isOilUser && !hasLinkParam && !hasTokenParam) {
    return next('/logout');
  }

  // If OIL user has a token param, validate it
  if (isOilUser && hasTokenParam) {
    try {
      const decoded = jwt_decode(to.query.token);
      const isOilTokenValid = decoded.exp * 1000 > Date.now();
      if (!isOilTokenValid) {
        return next('/logout');
      }
    } catch (error) {
      console.error('Failed to validate OIL token.', error);
      return next('/logout');
    }
  }

  // Check if user is pending approval or rejected
  const isPendingOrRejected =
    currentUser.isPendingApproval || currentUser.isRejected;
  // If user is not approved but is not on the welcome page, send them there
  if (isPendingOrRejected && to.fullPath !== '/new-user-welcome-page') {
    return next('/new-user-welcome-page');
  }

  // If user is already approved but is on the welcome page, redirect to dashboard
  if (!isPendingOrRejected && to.fullPath === '/new-user-welcome-page') {
    return next('/view/dashboard');
  }

  // Ensure the user has permission for the route's tool
  const pathSegments = to.path.split('/');
  const isAccessingTool = pathSegments.length > 2;

  if (isAccessingTool) {
    const tool = pathSegments[2];
    const defaultTools = ['dashboard', 'profile', 'version', 'fleetdata'];

    const hasPermission =
      defaultTools.includes(tool) ||
      store.state.currentUserAllowedTools.some((allowed) => allowed === tool);

    if (!hasPermission) {
      console.warn('No Permissions');
      return next('/404');
    }
  }

  // All checks passed
  return next();
});
window.vueRouter = router;

export default router;
