/* eslint-disable no-else-return */
import QuestionDTO from '../shared/dtos/QuestionDTO';
import {
  createRecord,
  getRecord,
  getRecordsUsingIndex,
  updateRecord,
} from '../services/db';
import { baseMarkForDelete, extractUrlSegment, matchRoute } from './base';

const clearQuestionsMeasurements = (question) => {
  question.image = [];
  question.lastModifiedAt = '';
  question.lastModifiedBy = '';
  switch (question.type) {
    case 'yesno':
      question.yesOrNo = '';
      break;

    case 'textnumber':
      question.inputValue = '';
      break;

    case 'action':
      question.checked = false;
      break;

    case 'multiplechoice':
      question.multipleChoices.map((mc) => {
        mc.checked = false;
      });

      break;

    case 'tablequestion':
      for (let i = 0; i < question.tableData.length; i++) {
        const row = question.tableData[i];
        for (let j = 0; j < row.length; j++) {
          if (i > 0 && j > 0) {
            row[j] = '';
          }
        }
      }
      break;

    case 'importcsv':
      break;
    default:
      throw new Error(`Failed to parse question type '${question.type}'`);
  }
  return question;
};

const handlers = {
  // POST Handlers
  'POST questions/:taskId': async ({ taskId }, body) => {
    const data = body;

    const user = await getRecord('session', 'user');

    const questionCreatePromises = [];
    data.forEach(async (inspection) => {
      const questionData = new QuestionDTO({
        ...inspection,
        taskId,
        lastModifiedBy: user.fullname,
      });

      // Check if the question already exists
      const existingQuestion = await getRecord('question', questionData._id);
      if (existingQuestion) {
        questionCreatePromises.push(updateRecord('question', questionData));
      } else {
        questionCreatePromises.push(createRecord('question', questionData));
      }
    });

    await Promise.all(questionCreatePromises);

    return 'created';
  },
  'POST question/:taskId': async ({ taskId }, body) => {
    const question = body;

    const questionData = new QuestionDTO({
      ...question,
      taskId,
    });

    return createRecord('question', questionData, false, body.checkedJobId);
  },
};

const offlineGet = async (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  if (extractUrlSegment(url, 0) === 'questions' && extractUrlSegment(url, 1)) {
    const taskId = splittedUrl[2];
    return getRecordsUsingIndex('question', 'taskId', taskId);
  }

  throw new Error(`Failed to handle ${url} with method GET`);
};

const offlinePost = async (url, body, ...args) => {
  const { handler, params, url: fullUrl } = matchRoute('POST', url, handlers);
  return handler(params, body, fullUrl, args);
};

const offlinePut = async (...args) => {
  const url = args[0];
  const body = args[1];
  const questionId = extractUrlSegment(url, 1);
  if (extractUrlSegment(url, 0) === 'question' && questionId) {
    const data = body;
    const { isInspector } = data;

    const questionToUpdate = {
      ...data,
      _id: questionId,
    };

    const user = await getRecord('session', 'user');

    // Only Inspector should appear in reports after an inspection is done
    if (isInspector) {
      questionToUpdate.lastModifiedBy = user.fullname;
      questionToUpdate.lastModifiedAt = new Date().toISOString();
      delete questionToUpdate.isInspector;
    }
    await updateRecord('question', questionToUpdate);

    return questionToUpdate;
  }

  throw new Error(`Failed to handle ${url} with method PUT`);
};

const offlineDelete = async (...args) => {
  const url = args[0];
  const questionId = extractUrlSegment(url, 1);
  if (extractUrlSegment(url, 0) === 'question' && questionId) {
    return baseMarkForDelete(...args);
  }

  throw new Error(`Failed to handle ${url} with method DELETE`);
};

export {
  clearQuestionsMeasurements,
  offlineGet,
  offlinePost,
  offlinePut,
  offlineDelete,
};
