import EditorCreateTask from '../components/Editor/AddTask';
import EditorCopyFrom from '../components/Editor/CopyFrom';
import EditorCreate from '../components/Editor/Create';
import EditorList from '../components/Editor/List';
import EditorTasks from '../components/Editor/Tasks';
import EditorTaskView from '../modules/Editor/views/TaskView';

const editorRoutes = [
  {
    path: '/view/editor',
    name: 'editor-list',
    component: EditorList,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/create',
    name: 'editor-create',
    component: EditorCreate,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/:id',
    name: 'editor-tasks',
    component: EditorTasks,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/:id/edit',
    name: 'editor-edit-job',
    component: EditorCreate,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/:id/create',
    name: 'editor-create-task',
    component: EditorCreateTask,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/:id/copyfrom',
    name: 'editor-copyfrom',
    component: EditorCopyFrom,
    meta: { authRequired: true },
  },
  {
    path: '/view/editor/:id/:taskNumber',
    name: 'editor-template',
    component: EditorTaskView,
    meta: { authRequired: true, needsTaskLock: true },
  },
];

export default editorRoutes;
