import { baseDelete, basePatch, basePost, basePut } from './base';

import { getRecords } from '../services/db';

const offlineGet = async () => {
  return getRecords('role');
};

const offlinePost = async (...args) => {
  return basePost(...args);
};

const offlinePut = async (...args) => {
  return basePut(...args);
};

const offlinePatch = async (...args) => {
  return basePatch(...args);
};

const offlineDelete = async (...args) => {
  return baseDelete(...args);
};

export { offlineGet, offlinePost, offlinePut, offlinePatch, offlineDelete };
