<template>
  <div id="adminTemplate">
    <div class="admin-header">
      <div style="display: inline-flex">
        <section>
          <b-tabs v-model="activeTab" position="is-left" class="block">
            <b-tab-item class="tab-option" label="User" />
            <b-tab-item class="tab-option" label="Role" />
            <b-tab-item
              class="tab-option"
              v-if="this.user.privil !== 0 || this.user.role === 'admin'"
              label="Sites"
            />
            <b-tab-item
              class="tab-option"
              v-if="this.user.privil !== 0"
              label="Mte Types"
            />
            <b-tab-item
              class="tab-option"
              v-if="this.user.privil !== 0"
              label="Notification Banner"
            />
          </b-tabs>
        </section>
      </div>

      <div class="admin-right-section">
        <div v-if="activeTab !== 4" class="admin-search">
          <form v-on:submit.prevent="adminSearch">
            <b-field>
              <b-input
                :placeholder="$t('search')"
                type="search"
                icon="magnify"
                icon-clickable
                class="search-input"
                v-model="search"
                @icon-click="adminSearch"
              >
              </b-input>
            </b-field>
          </form>
        </div>
        <b-button
          @click="openAddRole"
          v-if="activeTab === 1"
          class="button-add-item"
          >Add Role</b-button
        >
        <b-button
          @click="openAddSite"
          v-if="activeTab === 2 && this.user.privil !== 0"
          class="button-add-item"
          >Add Site</b-button
        >
        <b-button
          @click="addMteType"
          v-if="activeTab === 3"
          class="button-add-item"
          >Add MTE Type</b-button
        >
      </div>
    </div>

    <users :search="search" ref="users" v-if="activeTab === 0" />
    <role-list :search="search" ref="roleList" v-if="activeTab === 1" />
    <site-list :search="search" ref="siteList" v-if="activeTab === 2" />
    <mtetype-list :search="search" ref="mteTypeList" v-if="activeTab === 3" />
    <announcement-banner :search="search" v-if="activeTab === 4" />
  </div>
</template>

<script>
import Users from './Users';
import RoleList from './RoleList';
import SiteList from './SiteList';
import MteTypeList from './MteTypeList';
import AnnouncementBanner from './AnnouncementBanner';
import adminPageConstants from '../../constants/adminPageConstants';
import utilities_set_search_text from '../../store/utilities/actionTypes';

const ADMIN_ROLE = 'admin';

export default {
  name: 'admin-template',
  components: {
    users: Users,
    'role-list': RoleList,
    'site-list': SiteList,
    'mtetype-list': MteTypeList,
    'announcement-banner': AnnouncementBanner,
  },

  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    canAddUser() {
      return this.user.role === ADMIN_ROLE;
    },
    search: {
      get() {
        return this.$store.state.UtilitiesModule.search_text;
      },
      set(text) {
        this.$store.commit(utilities_set_search_text, {
          search_text: text,
        });
      },
    },
  },
  watch: {
    user: {
      async handler(val) {
        if (val && Object.keys(val).length > 0) {
          await this.adminSearch();
        }
      },
    },
    activeTab: {
      handler() {
        // timeout needed because activeTab is
        // changed before the component renders
        setTimeout(async () => {
          await this.adminSearch();
        }, 500);
      },
    },
  },
  async created() {
    const savedSelectedTab = this.$cookies.get(
      adminPageConstants.adminCookies.admin_selected_tab
    );
    if (savedSelectedTab != null) {
      this.activeTab = parseInt(savedSelectedTab, 10);
    }
    this.$cookies.remove(adminPageConstants.adminCookies.admin_selected_tab);
  },
  async mounted() {
    this.adminSearch();
  },
  methods: {
    openAddRole() {
      this.$cookies.set(
        adminPageConstants.adminCookies.admin_selected_tab,
        this.activeTab
      );
      this.$router.push({
        name: 'create-role',
      });
    },
    // openAddUser() {
    //   this.$cookies.set(
    //     adminPageConstants.adminCookies.admin_selected_tab,
    //     this.activeTab
    //   );
    //   this.$router.push({
    //     name: 'create-user',
    //   });
    // },
    openAddSite() {
      this.$cookies.set(
        adminPageConstants.adminCookies.admin_selected_tab,
        this.activeTab
      );
      this.$router.push({
        name: 'create-site',
      });
    },
    addMteType() {
      this.$cookies.set(
        adminPageConstants.adminCookies.admin_selected_tab,
        this.activeTab
      );
      this.$router.push({
        name: 'mtetype-create',
      });
    },
    async adminSearch() {
      const query = {
        search: this.search,
      };

      switch (this.activeTab) {
        case 0:
          await this.$refs.users.getUsers(query);
          break;
        case 1:
          await this.$refs.roleList.getRoles(query);
          break;
        case 2:
          await this.$refs.siteList.getSites(query);
          break;
        case 3:
          await this.$refs.mteTypeList.getMteTypes(query);
          break;
        default:
          break;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.admin-header {
  font-family: 'Siemens Sans';
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #fafafa;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: #333;
  padding-left: 20px;

  .admin-right-section {
    float: right;
    display: inline-flex;
    .admin-search {
      height: 100%;
      padding: 5px 20px;
      background-color: #e0e0e0;
      .admin-search-input input {
        border: 0px !important;
        background-color: #e0e0e0 !important;
        outline: none;
        box-shadow: none;
      }
    }

    .button-add-item {
      background-color: #4a86e8;
      color: white;
      height: 100%;
      width: 40%;

      &.disabled {
        background-color: lightgray;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }
  .list-type {
    cursor: pointer;
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin: 0 20px;
    min-width: 150px;
    float: left;
    &.active {
      border-bottom: 3px solid #4a86e8;
    }
  }

  .tabs li.is-active a {
    border-bottom-color: $siemens-logo-color;
  }

  .tabs a {
    border-bottom-width: 2px;
  }

  .b-tabs .tab-content {
    padding: 0;
  }
}
</style>
