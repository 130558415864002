import * as task from '../offline/task';
import * as job from '../offline/job';
import * as bubble from '../offline/bubble';
import * as question from '../offline/question';
import * as user from '../offline/user';
import * as site from '../offline/site';
import * as role from '../offline/role';
import { cacheRecord } from './db';

function mapApiToDbEntity(apiEntity) {
  const apiDatabaseMapper = {
    bubbles: 'bubble',
    questions: 'question',
    users: 'user',
    userRoles: 'role',
    sites: 'site',
    settings: 'setting',
  };

  const dbEntity = apiDatabaseMapper[apiEntity];
  if (dbEntity) {
    return dbEntity;
  }

  return apiEntity;
}

const responseObjectBuilder = (response) => {
  return {
    data: response,
  };
};

async function offlineGet(...args) {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = mapApiToDbEntity(splittedUrl[1]);
  let response;
  switch (recordType) {
    case 'task':
      response = task.offlineGet(...args);
      break;
    case 'job':
      response = job.offlineGet(...args);
      break;
    case 'bubble':
      response = bubble.offlineGet(...args);
      break;
    case 'question':
      response = question.offlineGet(...args);
      break;
    case 'user':
      response = user.offlineGet(...args);
      break;
    case 'site':
      response = site.offlineGet(...args);
      break;
    case 'role':
      response = role.offlineGet(...args);
      break;
    default:
      console.log(`No offline support for url ${url} with method GET`);
      return new Promise((resolve) => {
        resolve(null);
      });
    // throw new Error(`No offline support for url ${url} with method GET`);
  }

  return response.then(responseObjectBuilder);
}

async function offlinePost(...args) {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = mapApiToDbEntity(splittedUrl[1]);
  let response;
  switch (recordType) {
    case 'task':
      response = task.offlinePost(...args);
      break;
    case 'job':
      response = job.offlinePost(...args);
      break;
    case 'joblist':
      response = job.offlinePost(...args);
      break;
    case 'bubble':
      response = bubble.offlinePost(...args);
      break;
    case 'question':
      response = question.offlinePost(...args);
      break;
    case 'user':
      response = user.offlinePost(...args);
      break;
    default:
      throw new Error(`No offline support for url ${url} with method POST`);
  }

  return response.then(responseObjectBuilder);
}

async function offlinePut(...args) {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = mapApiToDbEntity(splittedUrl[1]);
  let response;
  switch (recordType) {
    case 'task':
      response = task.offlinePut(...args);
      break;
    case 'job':
      response = job.offlinePut(...args);
      break;
    case 'bubble':
      response = bubble.offlinePut(...args);
      break;
    case 'question':
      response = question.offlinePut(...args);
      break;
    case 'user':
      response = user.offlinePut(...args);
      break;
    default:
      throw new Error(`No offline support for url ${url} with method PUT`);
  }

  return response.then(responseObjectBuilder);
}

async function offlinePatch(...args) {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = mapApiToDbEntity(splittedUrl[1]);
  let response;
  switch (recordType) {
    case 'task':
      response = task.offlinePatch(...args);
      break;
    case 'job':
      response = job.offlinePatch(...args);
      break;
    case 'user':
      response = user.offlinePatch(...args);
      break;
    default:
      throw new Error(`No offline support for url ${url} with method PATCH`);
  }

  return response.then(responseObjectBuilder);
}

async function offlineDelete(...args) {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = mapApiToDbEntity(splittedUrl[1]);
  let response;
  switch (recordType) {
    case 'task':
      response = task.offlineDelete(...args);
      break;
    case 'job':
      response = job.offlineDelete(...args);
      break;
    case 'bubble':
      response = bubble.offlineDelete(...args);
      break;
    case 'question':
      response = question.offlineDelete(...args);
      break;
    case 'user':
      response = user.offlineDelete(...args);
      break;
    default:
      throw new Error(`No offline support for url ${url} with method DELETE`);
  }

  return response.then(responseObjectBuilder);
}

async function cacheData(dataPromise, ...args) {
  const url = args[0];
  const params = args[1]?.params;
  let { data } = await dataPromise;
  // Special cases
  if (url.includes('job') && params?.query) {
    data = data.jobs;
  } else if (!Array.isArray(data)) {
    data = new Array(data);
  }

  if (url === '/users/current') {
    data[0].id = data[0]._id;
    data[0]._id = 'user';
    cacheRecord('session', data[0]);
  }

  const recordType = mapApiToDbEntity(url.split('/')[1]);
  if (!recordType) {
    console.warn(
      `Tring to catch invalid record type for: ${url.split('/')[1]}`
    );
    return;
  }

  const promises = data.map((element) => cacheRecord(recordType, element));
  return Promise.all(promises);
}

export {
  offlineGet,
  offlinePost,
  offlinePut,
  offlinePatch,
  offlineDelete,
  mapApiToDbEntity,
  cacheData,
};
