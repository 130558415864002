import * as xlsx from 'xlsx';
import { v4 } from 'uuid';

const uuidv4 = v4;

export default {
  methods: {
    async exportRepairAssessment() {
      const workSheetJsonMinor = [];
      const workSheetJsonMajor = [];
      const isEditor = this.tool.includes('editor');
      const isPublisher = this.tool.includes('publisher');
      let minorHeader;
      let majorHeader;
      if (isEditor) {
        minorHeader = {
          header: ['Assembly', 'Item Number', 'Part Name', 'Quantity'],
        };
        majorHeader = {
          header: [
            'Level',
            'Assembly',
            'Item Number',
            'Part Name',
            'Serial Number',
            'Quantity',
          ],
        };
      } else if (isPublisher) {
        minorHeader = {
          header: [
            'Assembly',
            'Item Number',
            'Part Name',
            'Part Number',
            'Quantity',
          ],
        };
        majorHeader = {
          header: [
            'Level',
            'Assembly',
            'Item Number',
            'Part Name',
            'Part Number',
            'Serial Number',
            'Quantity',
          ],
        };
      } else {
        minorHeader = {
          header: [
            'Assembly',
            'Item Number',
            'Part Name',
            'Part Number',
            'Quantity',
            'Reuse',
            'Repair',
            'Replace',
            'Comments',
          ],
        };
        majorHeader = {
          header: [
            'Level',
            'Assembly',
            'Item Number',
            'Part Name',
            'Part Number',
            'Serial Number',
            'Quantity',
            'Visual',
            'Dim',
            'NDT',
            'Reuse',
            'Repair',
            'Replace',
            'Missing',
            'Received',
            'Summary',
            'Repair Scope',
            'Estimated Hours',
            'Eng. Review',
            'Client Rejected',
            'QC',
          ],
        };
      }

      const repairAssessment = await this.$store.dispatch(
        'getRepairAssessment',
        this.job
      );

      repairAssessment.forEach((row) => {
        if (row.isMinor) {
          if (isEditor) {
            workSheetJsonMinor.push({
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              Quantity: row.quantity,
            });
          } else if (isPublisher) {
            workSheetJsonMinor.push({
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              'Part Number': row.partNumber,
              Quantity: row.quantity,
            });
          } else {
            workSheetJsonMinor.push({
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              'Part Number': row.partNumber,
              Quantity: row.quantity,
              Reuse: row.reuse,
              Repair: row.repair,
              Replace: row.replace,
              Comments: row.comments,
            });
          }
        } else if (!row.isMinor) {
          if (isEditor) {
            workSheetJsonMajor.push({
              Level: row.level,
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              'serial Number': row.serialNumber,
              Quantity: row.quantity,
            });
          } else if (isPublisher) {
            workSheetJsonMajor.push({
              Level: row.level,
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              'Part Number': row.partNumber,
              'serial Number': row.serialNumber,
              Quantity: row.quantity,
            });
          } else {
            workSheetJsonMajor.push({
              Level: row.level,
              Assembly: row.assembly,
              'Item Number': row.itemNumber,
              'Part Name': row.partName,
              'Part Number': row.partNumber,
              'Serial Number': row.serialNumber,
              Quantity: row.quantity,
              Visual: row.visual,
              Dim: row.dim,
              NDT: row.ndt,
              Reuse: row.reuse,
              Repair: row.repair,
              Replace: row.replace,
              Missing: row.missing,
              Received: row.received,
              Summary: row.summary,
              'Repair Scope': row.repairScope,
              'Estimated Hours': row.estimatedLabor,
              'Eng. Review': row.reviewed,
              'Client Rejected': row.clientReject,
              QC: this.getQuality(row),
            });
          }
        }
      });

      const workbook = xlsx.utils.book_new();

      const sheetMinor = xlsx.utils.json_to_sheet(
        workSheetJsonMinor,
        minorHeader
      );
      const sheetMajor = xlsx.utils.json_to_sheet(
        workSheetJsonMajor,
        majorHeader
      );

      xlsx.utils.book_append_sheet(workbook, sheetMajor, 'Major');
      xlsx.utils.book_append_sheet(workbook, sheetMinor, 'Minor');

      const now = new Date();
      const datetimeString = `${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}${now.getMinutes()}`;
      const fileName = 'Major and Minor Components';
      xlsx.writeFile(
        workbook,
        `${fileName} - ${this.job.title} - ${datetimeString}.xlsx`
      );
    },
    getQuality(item) {
      if (this.shouldShowQuality(item)) {
        if (this.isQualityYellow(item)) {
          return 'yellow';
        }
        if (this.isQualityGreen(item)) {
          return 'green';
        }
        return 'red';
      }
      return 'N/A';
    },
    shouldShowQuality(item) {
      return (
        (item.reviewed &&
          item.reuse + item.repair + item.replace + item.missing !== 0) ||
        item.qualityControlOverride
      );
    },
    handleRepairAssessmentImportAdd(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (onloadEvent) => {
        const importedRepairAssessment = this.readRepairAssessmentFile(
          onloadEvent.target.result
        );

        if (!importedRepairAssessment) {
          return;
        }

        const importedRows = [];
        importedRepairAssessment.forEach((row) => {
          importedRows.push(row);
        });

        this.$store
          .dispatch('addRepairAssessmentRow', {
            job: this.job,
            rows: importedRows,
            tool: this.tool,
            replace: true,
            replaceMinor: true, // this.activeTab !== 0,
          })
          .then((createdRows) => {
            this.filteredRepairAssessment = [];
            if (!Array.isArray(createdRows)) {
              createdRows = [createdRows];
            }
            createdRows.forEach((row) => {
              this.filteredRepairAssessment.push(row);
            });
            this.job.repairAssessment = this.filteredRepairAssessment;
            this.refresh();
          });
      };
      fileReader.readAsBinaryString(file);
      // Clear the selected file so the user can reload the same file
      event.target.value = null;
    },
    readRepairAssessmentFile(binary) {
      const workbook = xlsx.read(binary, {
        type: 'binary',
      });

      const workSheetJsonMajor = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );

      const workSheetJsonMinor = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[1]],
        {
          header: 1,
        }
      );

      const headersMajor = workSheetJsonMajor[0].map((header) =>
        header.toLowerCase()
      );
      const headersMinor = workSheetJsonMinor[0].map((header) =>
        header.toLowerCase()
      );

      workSheetJsonMinor.splice(0, 1);
      workSheetJsonMajor.splice(0, 1);

      const repairAssessment = [];

      if (workbook.SheetNames[1].toLowerCase() === 'minor') {
        const assemblyLocation = headersMinor.indexOf('assembly');
        const itemNumberLocation = headersMinor.indexOf('item number');
        const partNameLocation = headersMinor.indexOf('part name');
        const partNumberLocation = headersMinor.indexOf('part number');
        const quantityLocation = headersMinor.indexOf('quantity');
        const reuseLocation = headersMinor.indexOf('reuse');
        const repairLocation = headersMinor.indexOf('repair');
        const replaceLocation = headersMinor.indexOf('replace');
        const commentsLocation = headersMinor.indexOf('comments');
        let validHeader = false;
        if (this.tool.includes('editor')) {
          validHeader =
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            quantityLocation !== -1;
        } else if (this.tool.includes('publisher')) {
          validHeader =
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            partNumberLocation !== -1 &&
            quantityLocation !== -1;
        } else {
          validHeader =
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            partNumberLocation !== -1 &&
            quantityLocation !== -1 &&
            reuseLocation !== -1 &&
            repairLocation !== -1 &&
            replaceLocation !== -1 &&
            commentsLocation !== -1;
        }

        if (!validHeader) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('repair_assessment_excel_import_failed'),
            type: 'is-danger',
          });
          return;
        }

        workSheetJsonMinor.forEach((row) => {
          const emptyRow =
            !row[assemblyLocation] &&
            !row[itemNumberLocation] &&
            !row[partNameLocation] &&
            !row[partNumberLocation] &&
            !row[quantityLocation] &&
            !row[reuseLocation] &&
            !row[repairLocation] &&
            !row[replaceLocation] &&
            !row[commentsLocation];
          if (!emptyRow) {
            repairAssessment.push({
              isMinor: true,
              id: uuidv4(),
              assembly: row[assemblyLocation] ? row[assemblyLocation] : '',
              itemNumber: row[itemNumberLocation]
                ? row[itemNumberLocation]
                : '',
              partName: row[partNameLocation] ? row[partNameLocation] : '',
              partNumber: row[partNumberLocation]
                ? row[partNumberLocation]
                : '',
              quantity: isNaN(row[quantityLocation])
                ? 0
                : row[quantityLocation],
              reuse: isNaN(row[reuseLocation]) ? 0 : row[reuseLocation],
              repair: isNaN(row[repairLocation]) ? 0 : row[repairLocation],
              replace: isNaN(row[replaceLocation]) ? 0 : row[replaceLocation],
              comments: row[commentsLocation] ? row[commentsLocation] : '',
            });
          }
        });
      }
      if (workbook.SheetNames[0].toLowerCase() === 'major') {
        const levelLocation = headersMajor.indexOf('level');
        const assemblyLocation = headersMajor.indexOf('assembly');
        const itemNumberLocation = headersMajor.indexOf('item number');
        const partNameLocation = headersMajor.indexOf('part name');
        const partNumberLocation = headersMajor.indexOf('part number');
        const serialNumberLocation = headersMajor.indexOf('serial number');
        const quantityLocation = headersMajor.indexOf('quantity');
        const visualLocation = headersMajor.indexOf('visual');
        const dimLocation = headersMajor.indexOf('dim');
        const ndtLocation = headersMajor.indexOf('ndt');
        const reuseLocation = headersMajor.indexOf('reuse');
        const repairLocation = headersMajor.indexOf('repair');
        const replaceLocation = headersMajor.indexOf('replace');
        const missingLocation = headersMajor.indexOf('missing');
        const receivedLocation = headersMajor.indexOf('received');
        const summaryLocation = headersMajor.indexOf('summary');
        const repairScopeLocation = headersMajor.indexOf('repair scope');
        const estimatedHoursLocation = headersMajor.indexOf('estimated hours');
        const engReviewLocation = headersMajor.indexOf('eng. review');
        const clientRejectedLocation = headersMajor.indexOf('client rejected');
        const qcLocation = headersMajor.indexOf('qc');
        const isEditor = this.tool.includes('editor');
        const isPublisher = this.tool.includes('publisher');

        let validHeader = false;
        if (isEditor) {
          validHeader =
            levelLocation !== -1 &&
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            serialNumberLocation !== -1 &&
            quantityLocation !== -1;
        } else if (isPublisher) {
          validHeader =
            levelLocation !== -1 &&
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            partNumberLocation !== -1 &&
            serialNumberLocation !== -1 &&
            quantityLocation !== -1;
        } else {
          validHeader =
            levelLocation !== -1 &&
            assemblyLocation !== -1 &&
            itemNumberLocation !== -1 &&
            partNameLocation !== -1 &&
            partNumberLocation !== -1 &&
            serialNumberLocation !== -1 &&
            quantityLocation !== -1 &&
            visualLocation !== -1 &&
            dimLocation !== -1 &&
            ndtLocation !== -1 &&
            reuseLocation !== -1 &&
            repairLocation !== -1 &&
            replaceLocation !== -1 &&
            missingLocation !== -1 &&
            receivedLocation !== -1 &&
            summaryLocation !== -1 &&
            repairScopeLocation !== -1 &&
            estimatedHoursLocation !== -1 &&
            engReviewLocation !== -1 &&
            clientRejectedLocation !== -1 &&
            qcLocation !== -1;
        }

        if (!validHeader) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('repair_assessment_excel_import_failed'),
            type: 'is-danger',
          });
          return;
        }

        if (this.tool.includes('editor')) {
          workSheetJsonMajor.forEach((row) => {
            const emptyRow =
              !row[levelLocation] &&
              !row[assemblyLocation] &&
              !row[itemNumberLocation] &&
              !row[partNameLocation] &&
              !row[serialNumberLocation] &&
              !row[quantityLocation];
            if (!emptyRow) {
              repairAssessment.push({
                id: uuidv4(),
                level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
                assembly: row[assemblyLocation] ? row[assemblyLocation] : '',
                itemNumber: row[itemNumberLocation]
                  ? row[itemNumberLocation]
                  : '',
                partName: row[partNameLocation] ? row[partNameLocation] : '',
                serialNumber: row[serialNumberLocation]
                  ? row[serialNumberLocation]
                  : '',
                quantity: isNaN(row[quantityLocation])
                  ? 0
                  : row[quantityLocation],
              });
            }
          });
        } else if (this.tool.includes('publisher')) {
          workSheetJsonMajor.forEach((row) => {
            const emptyRow =
              !row[levelLocation] &&
              !row[assemblyLocation] &&
              !row[itemNumberLocation] &&
              !row[partNameLocation] &&
              !row[partNumberLocation] &&
              !row[serialNumberLocation] &&
              !row[quantityLocation];

            if (!emptyRow) {
              repairAssessment.push({
                id: uuidv4(),
                level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
                assembly: row[assemblyLocation] ? row[assemblyLocation] : '',
                itemNumber: row[itemNumberLocation]
                  ? row[itemNumberLocation]
                  : '',
                partName: row[partNameLocation] ? row[partNameLocation] : '',
                partNumber: row[partNumberLocation]
                  ? row[partNumberLocation]
                  : '',
                serialNumber: row[serialNumberLocation]
                  ? row[serialNumberLocation]
                  : '',
                quantity: isNaN(row[quantityLocation])
                  ? 0
                  : row[quantityLocation],
              });
            }
          });
        } else {
          workSheetJsonMajor.forEach((row) => {
            const emptyRow =
              !row[levelLocation] &&
              !row[assemblyLocation] &&
              !row[itemNumberLocation] &&
              !row[partNameLocation] &&
              !row[partNumberLocation] &&
              !row[serialNumberLocation] &&
              !row[quantityLocation] &&
              !row[visualLocation] &&
              !row[dimLocation] &&
              !row[ndtLocation] &&
              !row[reuseLocation] &&
              !row[repairLocation] &&
              !row[replaceLocation] &&
              !row[missingLocation] &&
              !row[receivedLocation] &&
              !row[summaryLocation] &&
              !row[repairScopeLocation] &&
              !row[estimatedHoursLocation] &&
              !row[engReviewLocation] &&
              !row[clientRejectedLocation] &&
              !row[qcLocation];
            if (!emptyRow) {
              repairAssessment.push({
                id: uuidv4(),
                level: isNaN(row[levelLocation]) ? 0 : row[levelLocation],
                assembly: row[assemblyLocation] ? row[assemblyLocation] : '',
                itemNumber: row[itemNumberLocation]
                  ? row[itemNumberLocation]
                  : '',
                partName: row[partNameLocation] ? row[partNameLocation] : '',
                partNumber: row[partNumberLocation]
                  ? row[partNumberLocation]
                  : '',
                quantity: isNaN(row[quantityLocation])
                  ? 0
                  : row[quantityLocation],
                visual: row[visualLocation],
                dim: row[dimLocation],
                ndt: row[ndtLocation],
                reuse: row[reuseLocation],
                repair: row[repairLocation],
                replace: row[replaceLocation],
                missing: row[missingLocation],
                received: row[receivedLocation],
                summary: row[summaryLocation],
                repairScope: row[repairScopeLocation],
                estimatedLabor: row[estimatedHoursLocation],
                reviewed: row[engReviewLocation],
                clientReject: row[clientRejectedLocation],
                qualityControlOverride: row[qcLocation],
              });
            }
          });
        }
      }
      return repairAssessment;
    },
    isQualityGreen(item) {
      return (
        item.qualityControlOverride ||
        item.reuse === item.quantity ||
        (item.replace > 0 && item.received + item.reuse >= item.quantity)
      );
    },

    isQualityYellow(item) {
      return (
        !item.qualityControlOverride &&
        (!item.quantity ||
          item.quantity === 0 ||
          item.repair > 0 ||
          item.missing > 0 ||
          item.clientReject)
      );
    },
  },
};
