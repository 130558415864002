import client from '../../../config/api';
import { getRecord } from '../../../services/db';
import QuestionDTO from '../../../shared/dtos/QuestionDTO';
import SET_LOADING_STATE from '../../loading/mutations/mutationTypes';
import {
  LOCAL_SET_QUESTIONS,
  LOCAL_REMOVE_QUESTION,
  LOCAL_UPDATE_QUESTION,
} from '../mutations/mutationTypes';

export default {
  async SAVE_QUESTIONS(_1, payload) {
    const { taskId, data } = payload;
    return client.post(`/questions/${taskId}`, data);
  },
  async GET_QUESTIONS({ commit }, taskId) {
    const result = await client.get(`/questions/${taskId}`);
    commit(LOCAL_SET_QUESTIONS, result.data);
  },
  async DELETE_QUESTION({ commit }, questionId) {
    if (!questionId) {
      return;
    }

    await client.delete(`/question/${questionId}`);
    commit(LOCAL_REMOVE_QUESTION, questionId);
  },
  async CREATE_QUESTION({ commit }, data) {
    const { taskId, question } = data;
    if (!taskId || !question) {
      console.log('Missing requred params for request');
      return;
    }
    const questionDto = new QuestionDTO(question);

    const taskOfQuestion = await getRecord('task', taskId);
    if (taskOfQuestion) {
      const jobOfTask = await getRecord('job', taskOfQuestion.jobId);

      if (jobOfTask && jobOfTask.checkedOut && jobOfTask.checkedOut !== '') {
        questionDto.checkedJobId = jobOfTask._id;
      }
    }

    commit(SET_LOADING_STATE, { value: true }, { root: true });
    const response = await client.post(`/question/${taskId}`, questionDto);
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    return response.data;
  },
  async CREATE_QUESTIONS({ commit }, data) {
    console.log('CREATE_QUESTIONS', data);
    const { taskId, questions } = data;
    if (!taskId || questions.length === 0) {
      console.log('Missing requred params for request');
      return;
    }

    const questionsDTO = questions.map((question) => new QuestionDTO(question));

    commit(SET_LOADING_STATE, { value: true }, { root: true });
    const response = await client.post(`/questions/${taskId}`, questionsDTO);
    commit(SET_LOADING_STATE, { value: false }, { root: true });
    return response.data;
  },
  async UPDATE_QUESTION({ commit }, data) {
    const { _id } = data;

    if (!_id) {
      console.log('Missing requred params for request _id', _id);
      return;
    }
    const questionDto = new QuestionDTO(data);
    // commit(SET_LOADING_STATE, { value: true }, { root: true });

    try {
      const request = await client.put(`/question/${_id}`, {
        ...questionDto,
        isInspector: data.isInspector,
      });

      const question = new QuestionDTO(request.data);

      commit(SET_LOADING_STATE, { value: false }, { root: true });
      commit(LOCAL_UPDATE_QUESTION, question);
    } catch (error) {
      console.error('UPDATE_QUESTION failed:', error);

      const response = {
        error: error.message || 'An unexpected error occurred',
        status: error.status || 500,
      };

      // Special handling for 409 Conflict (Optimistic Locking)
      if (error.status === 409) {
        response.message =
          error.message ||
          'Data conflict detected. Another user has modified this data.';
        response.persistedData = error.persistedData || null;
        response.attemptedData = error.attemptedData || null;
      }

      return response;
    }
  },
};
