import { deleteRecord } from '../../../services/db';

export default {
  DELETE_RECORD_FROM_DB: (_, payload) => {
    const { recordType, recordId } = payload;
    deleteRecord(recordType, recordId);
  },
  SET_IS_OFFLINE_STATUS: (state, status) => {
    state.isOfflineMode = status;
  },
};
