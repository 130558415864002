<template>
  <div class="content">
    <h2 class="title is-4">Choose one item</h2>
    <div class="buttons are-medium buttons-content-layout">
      <b-button
        v-for="(item, index) in selectionItems"
        :key="index"
        type="is-light"
        @click="selectItem(item)"
        :disabled="item.disabled"
      >
        <div class="btn-center-content">
          <b-icon :icon="item.icon"></b-icon>
          {{ item.label }}
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selection',
  data() {
    return {
      selectionItems: [
        { type: 'action', label: 'Action', icon: 'check' },
        { type: 'yesno', label: 'Yes/No', icon: 'help' },
        { type: 'multiplechoice', label: 'Options', icon: 'menu' },
        { type: 'textnumber', label: 'Text', icon: 'pencil' },
        { type: 'tablequestion', label: 'Table', icon: 'table' },
        {
          type: 'importcsv',
          label: 'Import CSV',
          icon: 'file',
        },
      ],
    };
  },
  methods: {
    selectItem(item) {
      if (item.disabled) {
        return;
      }
      this.$emit('itemSelected', item);
    },
  },
};
</script>

<style scoped>
.disabled {
}

.buttons-content-layout {
  gap: 20px;
}

.btn-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 150px;
}
</style>
./selection.vue
