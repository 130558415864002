<template>
  <Layout :instruction="instruction">
    <template v-slot:title>
      <div>
        <span v-if="!isEditMode">Create new</span> Multiple Choice instruction
      </div>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <b-field class="full-width">
          <b-input
            type="textarea"
            size="is-small"
            v-model="question"
            :disabled="loading"
          ></b-input>
        </b-field>
        <div class="options">
          <div
            v-for="(option, index) in options"
            :key="index"
            class="option-item"
          >
            {{ index + 1 + '.' }}
            <b-field>
              <b-input
                v-model="option.defaultMC"
                placeholder="Enter an option"
              ></b-input>
              <a class="delete-option" @click="removeOption(index)">✖</a>
            </b-field>
          </div>
          <b-button type="is-primary" @click="addOption"
            >+ Add an option</b-button
          >
        </div>
        <b-field>
          <b-checkbox v-model="pictureMandatory">
            Picture taken is mandatory.</b-checkbox
          >
        </b-field>
      </div>
    </template>
    <template v-slot:reference-image>
      <ReferenceImage :instructionProps="instruction" ref="refImage" />
    </template>
    <template v-slot:footer>
      <b-loading
        :is-full-page="true"
        v-model="loading"
        :can-cancel="false"
      ></b-loading>
      <b-button
        :disabled="!instruction"
        type="is-danger"
        @click="$emit('onDelete')"
        >Delete</b-button
      >
      <b-button type="is-link" @click="$emit('onCancel')">Cancel</b-button>
      <b-button
        v-if="!instruction"
        type="is-success"
        :disabled="loading"
        @click="onSave"
        :loading="loadingButton"
        >Save</b-button
      >
      <b-button
        v-if="instruction"
        type="is-success"
        @click="onUpdate"
        :loading="loadingButton"
        >Update</b-button
      >
    </template>
  </Layout>
</template>

<script>
import Layout from './layout';
import ReferenceImage from '../ReferenceImage';
import { GET_LOADING_STATE } from '../../../../../store/loading/getters/getterTypes';
import KeyHandlerMixin from '../../../../../mixins/KeyHandlerMixin';

export default {
  name: 'multiple-choice',
  components: {
    Layout,
    ReferenceImage,
  },
  mixins: [KeyHandlerMixin],
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    instruction: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.instruction) {
      const { question, multipleChoices, isPictureMandatory } =
        this.instruction;
      this.question = question;
      this.options = multipleChoices
        ? JSON.parse(JSON.stringify(multipleChoices))
        : [{ checked: false }];
      this.pictureMandatory = isPictureMandatory;
    }
    this.onUpdateCallback = {
      callback: this.onUpdate,
      isUpdate: this.instruction,
    };
    this.onSaveCallback = {
      callback: this.onSave,
      isSave: !this.instruction,
    };
  },
  data() {
    return {
      question: '',
      options: [{ checked: false }],
      pictureMandatory: false,
      loadingButton: false,
    };
  },
  methods: {
    addOption() {
      this.options.push({ checked: false });
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
    onSave() {
      const { file, useImageReference } = this.$refs.refImage;
      this.loadingButton = true;
      this.$emit('onSave', {
        question: this.question,
        multipleChoices: this.options,
        isPictureMandatory: this.pictureMandatory,
        type: 'multiplechoice',
        file,
        useImageReference,
      });
    },
    onUpdate() {
      const { file, useImageReference } = this.$refs.refImage;
      this.loadingButton = true;
      this.$emit('onUpdate', {
        _id: this.instruction._id,
        ...this.instruction,
        question: this.question,
        multipleChoices: this.options,
        isPictureMandatory: this.pictureMandatory,
        type: 'multiplechoice',
        file,
        useImageReference,
      });
    },
  },
  computed: {
    loading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
  },
};
</script>

<style scoped>
button {
  flex: 1;
}

.content-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 40px;
}

.options {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.delete-option {
  margin-left: 10px;
  cursor: pointer;
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.full-width {
  width: 100%;
}
</style>
