/* eslint-disable no-else-return */
import {
  createRecord,
  deleteRecord,
  getRecord,
  getRecordsUsingIndex,
  removeAttributesFromRecord,
  updateRecord,
} from '../services/db';
import {
  baseGet,
  extractUrlSegment,
  getQueryParameters,
  matchRoute,
  uuidRegex,
} from './base';
import { clearBubbleMeasurements } from './bubble';
import { clearQuestionsMeasurements } from './question';
import getCurrentEpoch from '../helpers/DateTimeHelper';

const duplicateFolder = async (folderId, parentId) => {
  const folder = await getRecord('task', folderId);
  const newFolder = { ...folder };
  if (parentId) newFolder.folderId = parentId;
  let counter = 1;
  let newName = `${folder.taskName} (copy ${counter})`;

  const tasks = await getRecordsUsingIndex('task', 'jobId', folder.jobId);

  tasks.some((item) => {
    if (newName === item.taskName) {
      newName = newName.replace(/\d+/g, ++counter);
    }
  });

  newFolder.taskName = newName;

  const subTasks = getRecordsUsingIndex('task', 'folderId', folderId);

  const subFolders = [];
  const duplicateTransactions = [];

  subTasks.forEach(async (task) => {
    if (task.taskType === 'folder') {
      subFolders.push(task);
    } else {
      const baseTaskId = task._id;

      const newTask = { ...task };
      newTask.folderId = newFolder._id;
      const newNameTask = `${task.taskName} (copy ${counter})`;
      newTask.taskName = newNameTask;
      newTask.completenessPercentage = 0;

      await createRecord('task', newTask);

      const user = await getRecord('session', 'user');

      if (newTask.taskType === 'diagram') {
        const duplicateBubblePromises = getRecordsUsingIndex(
          'bubble',
          'taskId',
          baseTaskId
        ).then((bubbles) => {
          const createBubblePromises = [];

          bubbles.forEach((bubble) => {
            bubble.taskId = newTask._id;
            bubble.lastModifiedBy = user.fullname;
            clearBubbleMeasurements(bubble);
            createBubblePromises.push(createRecord('bubble', bubble));
          });

          return Promise.all(createBubblePromises);
        });

        duplicateTransactions.push(duplicateBubblePromises);
      } else if (newTask.taskType === 'instruction') {
        const duplicateQuestionPromises = getRecordsUsingIndex(
          'question',
          'taskId',
          baseTaskId
        ).then((questions) => {
          const createQuestionPromises = [];

          questions.forEach((question) => {
            question.taskId = newTask._id;
            question.lastModifiedBy = user.fullname;
            clearQuestionsMeasurements(question);
            createQuestionPromises.push(createRecord('question', question));
          });

          return Promise.all(createQuestionPromises);
        });

        duplicateTransactions.push(duplicateQuestionPromises);
      }
    }
  });

  await Promise.all(duplicateTransactions);

  createRecord('task', newFolder);
  if (subFolders.length > 0) {
    // TODO There seems to be an issue with this loop, but it's equivalent to the original implementation
    // Please review this
    // eslint-disable-next-line
    for (const subFolder of subFolders) {
      // eslint-disable-next-line
      const newSubFolder = await duplicateFolder(subFolder._id, newFolder._id);
      return [...newSubFolder, newFolder];
    }
  }

  return [newFolder];
};

const getTaskByIdFromJob = async (id, jobId) => {
  const tasks = await getRecordsUsingIndex('task', 'jobId', jobId).then(
    (existingTasks) => {
      return existingTasks.filter((task) => task._id === id);
    }
  );

  return tasks.length > 0 ? tasks[0] : undefined;
};

const getExistingFolder = async (folderName, jobId) => {
  const tasks = await getRecordsUsingIndex('task', 'jobId', jobId).then(
    (existingTasks) => {
      return existingTasks.filter(
        (task) => task.taskType === 'folder' && task.taskName === folderName
      );
    }
  );

  return tasks.length > 0 ? tasks[0] : null;
};

const createFolder = async (folderName, jobId, rootId) => {
  const folder = {};
  folder.taskType = 'folder';
  folder.taskName = folderName;
  folder.jobId = jobId;
  folder.fields = [];
  const tasks = await getRecordsUsingIndex('task', 'jobId', jobId);

  folder.taskOrder = tasks.length;
  if (rootId) {
    folder.folderId = rootId;
  }

  createRecord('task', folder);
  updateRecord('job', { _id: folder.jobId, taskCount: folder.taskOrder + 1 });
  return folder._id;
};

const handleSubfolder = async (taskFolder, taskJobId, jobId) => {
  const subfolderParent = await getTaskByIdFromJob(
    taskFolder.folderId,
    taskJobId
  );
  const subfolderParentInSourceTask = await getExistingFolder(
    subfolderParent.taskName,
    jobId
  );

  let subfolderParentId = '';
  if (subfolderParentInSourceTask) {
    subfolderParentId = subfolderParentInSourceTask._id;
  } else {
    subfolderParentId = await createFolder(
      subfolderParent.taskName,
      jobId,
      null
    );
  }

  if (subfolderParentInSourceTask) {
    const folderInSourceTask = await getRecordsUsingIndex(
      'task',
      'jobId',
      jobId
    ).then((tasks) => {
      return tasks.filter(
        (task) =>
          task.taskType === 'folder' &&
          task.taskName === taskFolder.taskName &&
          task.folderId === subfolderParentInSourceTask._id
      );
    });

    if (folderInSourceTask.length > 0) {
      return folderInSourceTask[0]._id;
    }
  }

  return createFolder(taskFolder.taskName, jobId, subfolderParentId);
};

const prepareFolder = async (taskFolder, taskJobId, jobId) => {
  const folderTitle = taskFolder.taskName;

  let returnFolderId = '';
  const isSubfolder = Boolean(taskFolder.folderId);

  const existingFolder = await getExistingFolder(taskFolder.taskName, jobId);

  if (existingFolder) {
    returnFolderId = existingFolder._id;
  } else if (isSubfolder) {
    returnFolderId = await handleSubfolder(taskFolder, taskJobId, jobId);
  } else {
    returnFolderId = await createFolder(folderTitle, jobId, null);
  }

  return returnFolderId;
};

const copyFrom = async (jobId, task) => {
  const tasksCount = (await getRecordsUsingIndex('task', jobId, jobId)).length;

  const taskOrder = tasksCount;

  task.taskOrder = taskOrder;
  task.beingModified = false;
  task.jobId = jobId;

  const taskOldId = task._id;

  const dbTask = await getRecord('task', taskOldId);

  task = {
    ...dbTask,
    ...task,
  };

  delete task.modifier;
  delete task.modifyStartTime;
  delete task.taskNumber;
  delete task._id;
  delete task.reportNote;
  delete task.workStatusCode;

  if (task.childConfig && task.childConfig[0]) {
    delete task.childConfig[0].lastModifiedBy;
  }

  task.taskNumber = task._id;
  task.taskComment = '';
  task.completenessPercentage = 0;

  const user = await getRecord('session', 'user');

  const duplicateTransactions = [];
  if (task.taskType === 'diagram') {
    const bubbles = await getRecordsUsingIndex('bubble', 'taskId', taskOldId);
    bubbles.forEach((bubble) => {
      bubble.taskId = task._id;
      bubble.lastModifiedBy = user.fullname;
      clearBubbleMeasurements(bubble);
      duplicateTransactions.push(createRecord('bubble', bubble));
    });
  } else if (task.taskType === 'instruction') {
    const questions = await getRecordsUsingIndex(
      'question',
      'taskId',
      taskOldId
    );

    questions.forEach((question) => {
      question.taskId = task._id;
      question.lastModifiedBy = user.fullname;
      clearQuestionsMeasurements(question);
      duplicateTransactions.push(createRecord('question', question));
    });
  }

  await Promise.all(duplicateTransactions);

  const allTasks = await getRecordsUsingIndex('task', 'jobId', jobId);

  const existingTaskNames = allTasks.map((t) => t.taskName);
  const baseNamePattern = new RegExp(`^${task.taskName}( \\(copy (\\d+)\\))?$`);
  let maxCopyNumber = 0;

  existingTaskNames.forEach((name) => {
    const match = name.match(baseNamePattern);
    if (match) {
      const copyNumber = match[2] ? parseInt(match[2], 10) : 0;
      if (copyNumber > maxCopyNumber) {
        maxCopyNumber = copyNumber;
      }
    }
  });

  if (maxCopyNumber > 0) {
    task.taskName = `${task.taskName} (copy ${maxCopyNumber + 1})`;
  } else if (existingTaskNames.includes(task.taskName)) {
    task.taskName = `${task.taskName} (copy 1)`;
  }

  createRecord('task', task);
  updateRecord('job', {
    _id: task.jobId,
    taskCount: (task.taskOrder !== undefined ? task.taskOrder : 0) + 1,
  });

  return { message: 'success' };
};

const updateJobThumbnail = async (jobId) => {
  const tasks = await getRecordsUsingIndex('task', 'jobId', jobId);

  // Currently only supports diagrams
  // TODO Add support for instructions

  // Use the image from the first task
  // TODO Use the image from the first task that has pictures
  const diagramTasks = tasks.filter((task) => task.taskType === 'diagram');

  let thumbnailUrl;
  if (diagramTasks.length > 0) {
    const firstTask = diagramTasks[0];
    const lastPart = firstTask.diagram.src.split('/').pop();
    const allButLastPart = firstTask.diagram.src
      .split('/')
      .slice(0, -1)
      .join('/');

    thumbnailUrl = `${allButLastPart}/${encodeURIComponent(lastPart)}`;
  }

  return getRecord('job', jobId).then((job) => {
    // Only save if there are changes
    if (thumbnailUrl !== job.thumbnailUrl) {
      return updateRecord('job', { _id: jobId, thumbnailUrl });
    }
  });
};

const putHandlers = {
  'PUT task/:taskId': async ({ taskId }, body) => {
    await updateRecord('task', {
      _id: taskId,
      body,
    });
    await updateJobThumbnail(body.jobId);
    return { message: 'Task was successfully updated' };
  },
  'PUT task/:taskId/mte/:mteId': async ({ taskId, mteId }, body) => {
    const task = getRecord('task', taskId);
    const mtes = task.mtes || [];

    const existingMte = mtes.find((mte) => mte.id === mteId);

    const mteToUpdate = body;

    existingMte.type = mteToUpdate.type;
    existingMte.dueDate = mteToUpdate.dueDate;
    existingMte.master = mteToUpdate.master;

    updateRecord('task', {
      _id: taskId,
      mtes,
    });

    return existingMte;
  },
  'PUT task/:taskId/undelete': async ({ taskId }) => {
    removeAttributesFromRecord('task', taskId, [
      'deleted',
      'deletedBy',
      'deletedAt',
      'folderId',
    ]);

    const task = await getRecord('task', taskId);

    const job = await getRecord('job', task.jobId);

    const taskCount = parseInt(job.taskCount, 10)
      ? parseInt(job.taskCount, 10) - 1
      : 0;

    updateRecord('job', {
      _id: job._id,
      taskCount,
    });

    await updateJobThumbnail(job._id);

    return { message: 'Task was successfully recovered' };
  },
  'PUT task/save/new': async (_, body) => {
    const savedTask = updateRecord('task', body);

    await updateJobThumbnail(body.jobId);

    return savedTask;
  },
  'PUT task/save/:taskId': async ({ taskId }, taskJson) => {
    // Removing unnecessary properties.
    if (taskJson.hasTabUnloaded !== undefined) {
      delete taskJson.hasTabUnloaded;
    }
    // delete unnecessary redundant fields before updating
    if (taskJson.taskNumber) {
      delete taskJson.taskNumber;
    }
    if (typeof taskJson.workOrderName === 'string') {
      taskJson.workOrderName = [{ text: taskJson.workOrderName }];
    }
    if (typeof taskJson.brand === 'string') {
      taskJson.brand = [{ text: taskJson.brand }];
    }
    if (typeof taskJson.frameType === 'string') {
      taskJson.frameType = [{ text: taskJson.frameType }];
    }
    if (typeof taskJson.model === 'string') {
      taskJson.model = [{ text: taskJson.model }];
    }

    updateRecord('task', { ...taskJson, _id: taskId });

    await updateJobThumbnail(taskJson.jobId);

    return { message: 'Task was successfully saved' };
  },
  'PUT task/move/:tasksId': async ({ tasksId }, body) => {
    const folderId = body.folderId || '';

    const tasks = [];

    const getRecordPromises = [];
    tasksId.forEach((taskId) => {
      getRecordPromises.push(getRecord('task', taskId));
    });

    tasks.push(...(await Promise.all(getRecordPromises)));

    let folder;
    if (folderId !== '') {
      folder = getRecord('task', folderId);
    }

    const [firstTask] = tasks;
    const expectedJobId = firstTask.jobId;

    // Sort tasks by order
    tasks.sort((a, b) => {
      if (a.taskOrder > b.taskOrder) {
        return 1;
      }

      if (a.taskOrder < b.taskOrder) {
        return -1;
      }

      return 0;
    });

    // Move all tasks to the target folder or root
    tasks.forEach((task) => (task.folderId = folderId));

    const folderTasks = await getRecordsUsingIndex(
      'task',
      'jobId',
      expectedJobId
    ).then((existingTasks) => {
      return existingTasks.filter((task) =>
        folderId === '' ? !task.folderId : task.folderId === folderId
      );
    });

    const maxFolderOrder =
      folderTasks.length === 0
        ? folder.taskOrder
        : Math.max(...folderTasks.map((ft) => ft.taskOrder));
    const nextTaskOrder = maxFolderOrder + 1;

    tasks.forEach((task, index) => (task.taskOrder = nextTaskOrder + index));

    const updateRecordPromises = [];
    tasks.forEach((task) => {
      updateRecordPromises.push(updateRecord('task', task));
    });

    Promise.all(updateRecordPromises);

    return { status: true };
  },
};

const postHandlers = {
  'POST task/:taskId/mte': async ({ taskId }, body) => {
    const task = await getRecord('task', taskId);
    const mtes = task.mtes || [];
    const currentUser = await getRecord('session', 'currentUser');
    body.createdBy = currentUser.fullname;
    body.createdAt = new Date();
    mtes.push(body);
    updateRecord('task', { ...task, mtes });
  },
  'POST task/duplicateFolder/:folderId': async ({ folderId }) => {
    const newFolders = duplicateFolder(folderId);

    return [newFolders];
  },
  'POST task/signleadman/:taskId': async ({ taskId }, body) => {
    // Sign Leadman
    const { isSign } = body;

    let workStatusCode;
    if (isSign) {
      workStatusCode = '2';
    } else {
      workStatusCode = '1';
    }
    const savedTask = await getRecord('task', taskId);
    updateRecord('task', { ...savedTask, workStatusCode, _id: taskId });

    return { message: 'Task was successfully sign by leadman' };
  },
  'POST task/copy/:jobId': async ({ jobId }, body) => {
    // Copy tasks
    const { task } = body;
    const taskJobId = task.jobId;
    const taskFolderId = task.folderId;

    if (taskFolderId) {
      const taskFolder = await getTaskByIdFromJob(taskFolderId, taskJobId);

      const folderId = await prepareFolder(taskFolder, taskJobId, jobId);

      task.folderId = folderId;
    }

    return copyFrom(jobId, task);
  },
};

const handlers = {
  ...putHandlers,
  ...postHandlers,
};

const offlineGet = async (...args) => {
  const url = args[0];
  let params;
  if (args[1]) {
    params = args[1].params;
  }
  const splittedUrl = url.split('/');
  const taskId = extractUrlSegment(url, 1);
  if (
    extractUrlSegment(url, 0) === 'task' &&
    extractUrlSegment(url, 1) === 'job' &&
    extractUrlSegment(url, 2)
  ) {
    // Get job tasks
    const jobIdAndOptions = splittedUrl[3];
    const jobIdAndOptionsSplitted = jobIdAndOptions.split('?');
    const jobId = jobIdAndOptionsSplitted[0];

    // The only used option as of now is folderId, so expect one parameter at most
    // Check for folderId in params, jobIdAndOptions or query parameters
    let subTasks = null;
    if ((params && params.folderId) || jobIdAndOptionsSplitted.length > 1) {
      const queryParameters = getQueryParameters(url);
      const folderId =
        jobIdAndOptionsSplitted[1] ||
        params.folderId ||
        queryParameters.folderId;

      subTasks = await getRecordsUsingIndex('task', 'folderId', folderId);
    } else {
      subTasks = await getRecordsUsingIndex('task', 'jobId', jobId);
      subTasks = subTasks.filter((task) => !task.folderId);
    }
    subTasks = subTasks.filter((task) => !task.deleted);
    return subTasks;
  } else if (extractUrlSegment(url, 0) === 'task' && taskId) {
    return baseGet(...args);
  }
  throw new Error(`Failed to handle ${url} with method GET`);
};

const offlinePost = async (url, body) => {
  const { handler, params, url: fullUrl } = matchRoute('POST', url, handlers);
  return handler(params, body, fullUrl);
};

const offlinePut = async (url, body) => {
  const { handler, params, url: fullUrl } = matchRoute('PUT', url, handlers);
  return handler(params, body, fullUrl);
};

const offlinePatch = async (...args) => {
  const url = args[0];
  const body = args[1];
  const splittedUrl = url.split('/');
  if (/^\/tasks/.test(url)) {
    const tasksOrder = body;

    tasksOrder.forEach(async (taskOrder) => {
      await updateRecord('task', {
        _id: taskOrder.id,
        taskOrder: taskOrder.order,
      });
    });

    return { message: 'Tasks were successfully updated' };
  } else if (/^\/update/.test(url)) {
    const { id } = splittedUrl[0];

    const tasksToBeUpdated = body;

    updateRecord('task', { ...tasksToBeUpdated, _id: id });

    await updateJobThumbnail(tasksToBeUpdated.jobId);

    return { message: 'Task was successfully updated' };
  }

  throw new Error(`Failed to handle ${url} with method PATCH`);
};

const offlineDelete = async (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  if (new RegExp(`^/task/${uuidRegex}`).test(url)) {
    const { id } = splittedUrl[1];

    const task = await getRecord('task', id);

    const deleteTask = async (taskToBeDeleted) => {
      if (taskToBeDeleted.taskType === 'folder') {
        const folderTasks = await getRecordsUsingIndex(
          'task',
          'jobId',
          taskToBeDeleted.jobId
        ).then((tasks) =>
          tasks.filter((t) => (t.folderId = taskToBeDeleted._id))
        );

        const deleteTaskPromises = [];
        folderTasks.forEach((subTask) =>
          deleteTaskPromises.push(deleteTask(subTask))
        );
        await Promise.all(deleteTaskPromises);

        await deleteRecord('task', taskToBeDeleted._id);
      } else {
        const currentUser = await getRecord('session', 'currentUser');
        await updateRecord('task', {
          _id: task._id,
          deleted: true,
          deletedBy: currentUser.fullName,
          deletedAt: getCurrentEpoch(),
        });
      }
    };

    await deleteTask(task);

    const job = await getRecord('job', task.jobId);
    const taskCount = await getRecordsUsingIndex('task', 'jobid', job._id).then(
      (tasks) =>
        tasks.filter(
          (t) =>
            (t.taskType === 'diagram' || t.taskType === 'instruction') &&
            !t.deleted
        ).length
    );

    // if last task is deleted, remove job thumbnail
    await updateRecord('job', { _id: task.jobId, taskCount });

    await updateJobThumbnail(task.jobId);

    return { message: 'Task was successfully deleted' };
  } else if (
    /^\/task\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}\/mte\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}/.test(
      url
    )
  ) {
    if (new RegExp(`^/task/${uuidRegex}/mte/${uuidRegex}`).test(url)) {
      const taskId = splittedUrl[1];
      const mteId = splittedUrl[3];

      const task = getRecord('task', taskId);

      const mtes = task.mtes || [];

      const existingMte = mtes.find((mte) => mte.id === mteId);

      const existingMteIndex = mtes.findIndex((mte) => mte.id === mteId);
      mtes.splice(existingMteIndex, 1);

      updateRecord('task', { _id: taskId, mtes });

      return existingMte;
    }
  }

  throw new Error(`Failed to handle ${url} with method DELETE`);
};

export { offlineGet, offlinePost, offlinePut, offlinePatch, offlineDelete };
