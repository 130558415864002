<template>
  <div class="dij-rat-content">
    <b-field label="Label">
      <b-numberinput placeholder="RAT" :controls="false" v-model="label" />
    </b-field>
    <b-tabs type="is-boxed" v-model="activeTab">
      <b-tab-item label="Major Component" icon="engine" v-if="showMajor">
        <b-field label="Level">
          <b-numberinput :min="1" :controls="false" v-model="level" />
        </b-field>

        <b-field label="Assembly">
          <b-input v-model="inspection.assembly" />
        </b-field>

        <b-field label="Item Number">
          <b-input v-model="inspection.itemNumber" />
        </b-field>

        <b-field label="Part Name">
          <b-input v-model="inspection.partName" />
        </b-field>

        <b-field label="Part Number">
          <b-input
            v-model="inspection.partNumber"
            :disabled="tool === 'editor'"
          />
        </b-field>

        <b-field label="Quantity">
          <b-numberinput :min="1" :controls="false" v-model="quantity" />
        </b-field>
        <template v-if="showExtendedRAT">
          <b-field label="Visual">
            <b-input v-model="inspection.visual" type="textarea" />
          </b-field>

          <b-field label="Dim">
            <b-input v-model="inspection.dim" type="textarea" />
          </b-field>

          <b-field label="NDT">
            <b-input v-model="inspection.ndt" type="textarea" />
          </b-field>

          <b-field label="Reuse">
            <b-numberinput :controls="false" v-model="inspection.reuse" />
          </b-field>

          <b-field label="Repair">
            <b-numberinput :controls="false" v-model="inspection.repair" />
          </b-field>

          <b-field label="Replace">
            <b-numberinput :controls="false" v-model="inspection.replace" />
          </b-field>

          <b-field label="Missing">
            <b-numberinput :controls="false" v-model="inspection.missing" />
          </b-field>

          <b-field label="Summary">
            <b-input v-model="inspection.summary" type="textarea" />
          </b-field>

          <b-field label="Repair Scope">
            <b-input v-model="inspection.repairScope" type="textarea" />
          </b-field>

          <b-field label="Est. Hours">
            <b-input v-model="inspection.estimatedLabor" />
          </b-field>

          <b-field label="Received">
            <b-numberinput :controls="false" v-model="inspection.received" />
          </b-field>
        </template>
      </b-tab-item>
      <b-tab-item
        label="Minor Component"
        icon="screw-flat-top"
        v-if="showMinor"
      >
        <b-field label="Assembly">
          <b-input v-model="inspection.assembly" />
        </b-field>

        <b-field label="Item Number">
          <b-input v-model="inspection.itemNumber" />
        </b-field>

        <b-field label="Part Name">
          <b-input v-model="inspection.partName" />
        </b-field>

        <b-field label="Part Number">
          <b-input v-model="inspection.partNumber" />
        </b-field>

        <b-field label="Quantity">
          <b-numberinput :controls="false" v-model="inspection.quantity" />
        </b-field>

        <b-field label="Reuse">
          <b-numberinput :controls="false" v-model="inspection.reuse" />
        </b-field>

        <b-field label="Repair">
          <b-numberinput :controls="false" v-model="inspection.repair" />
        </b-field>

        <b-field label="Replace">
          <b-numberinput :controls="false" v-model="inspection.replace" />
        </b-field>

        <b-field label="Comments">
          <b-input v-model="inspection.comments" type="textarea" />
        </b-field>
      </b-tab-item>
    </b-tabs>

    <b-field :label="$t('orientation')">
      <b-field class="dij-diagrambuilder-inspection-orientation">
        <b-button
          :type="inspection.orientation === 'up' ? 'is-primary' : ''"
          data-value="up"
          @click.native="updateMarkerOrientation"
        >
          <b-icon icon="chevron-down" />
        </b-button>

        <b-button
          :type="inspection.orientation === 'down' ? 'is-primary' : ''"
          data-value="down"
          @click.native="updateMarkerOrientation"
        >
          <b-icon icon="chevron-up" />
        </b-button>

        <b-button
          :type="inspection.orientation === 'right' ? 'is-primary' : ''"
          data-value="right"
          @click.native="updateMarkerOrientation"
        >
          <b-icon icon="chevron-left" />
        </b-button>

        <b-button
          :type="inspection.orientation === 'left' ? 'is-primary' : ''"
          data-value="left"
          @click.native="updateMarkerOrientation"
        >
          <b-icon icon="chevron-right" />
        </b-button>
      </b-field>
    </b-field>
  </div>
</template>
<script>
export default {
  name: 'rat-inspection-detail',
  props: ['value', 'tool'],
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    inspection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    label: {
      get() {
        if (this.inspection.label) return parseInt(this.inspection.label, 10);
        return null;
      },
      set(value) {
        if (value) {
          let numberValue = parseInt(value, 10);
          // Make sure the number is between 1 and 999 (due to size contraints)
          numberValue = Math.min(Math.max(numberValue, 1), 999);
          value = numberValue.toString();
        }
        this.inspection.label = value;

        const diagramLabel = this.inspection.marker.children[2];
        // Update the diagram marker label
        diagramLabel.content = value || 'RAT';
        // Center the diagram marker position
        diagramLabel.position = this.inspection.marker.children[1].position;
      },
    },

    level: {
      get() {
        if (this.inspection.level) return this.inspection.level;
        return null;
      },
      set(value) {
        this.inspection.level = value;
      },
    },

    quantity: {
      get() {
        if (this.inspection.quantity) return this.inspection.quantity;
        return null;
      },
      set(value) {
        this.inspection.quantity = value;
      },
    },

    showExtendedRAT() {
      return !['publisher', 'editor'].includes(this.tool);
    },

    isMinor() {
      if (this.value.isMinor) return this.value.isMinor;
      return this.activeTab === 1;
    },

    showMajor() {
      if (this.value.isMinor && this.value.ratRowID) return false;
      return true;
    },

    showMinor() {
      if (!this.value.isMinor && this.value.ratRowID) return false;
      return true;
    },
  },

  methods: {
    showNumber() {
      return (
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'line'
      );
    },

    showOrientation() {
      return (
        this.inspection.type !== 'length' &&
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'line'
      );
    },

    updateMarkerOrientation(event) {
      const oldValue = this.inspection.orientation;
      const { value } = event.target.closest('button').dataset;
      if (oldValue === value) {
        this.inspection.orientation = null;
      } else {
        this.inspection.orientation = value;
      }

      this.$parent.makeSimpleMarker(this.inspection);
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-rat-content {
  min-width: 400px;
  max-height: 500px;
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .dij-rat-content {
    max-height: 300px;
  }
}

.dij-diagrambuilder-inspection-unit {
  .control {
    flex: 0 0 50%;
  }
}
.dij-diagrambuilder-inspection-tolerance-type {
  .control {
    flex: 0 0 calc(100% / 3);
  }
}
.dij-diagrambuilder-inspection-orientation {
  padding-bottom: 20px;
  .button {
    flex: 0 0 25%;
  }
}
.dij-diagrambuilder-inspection-tolerances {
  ::v-deep div.control input.input {
    width: 70px;
  }
}
.dij-diagrambuilder-inspection-measurementcount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
// lower the text size for label and fields
.field ::v-deep label,
.control ::v-deep input {
  font-size: 75%;
}
// Disable the input number arrows
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}
</style>
