import actions from './actions';

const repairSelectModule = {
  namespaced: true,
  state() {
    return {};
  },
  actions,
};

export default repairSelectModule;
