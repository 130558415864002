<template>
  <Layout class="table-question-layout">
    <b-loading
      :is-full-page="false"
      :active.sync="loading"
      :can-cancel="false"
    ></b-loading>
    <template v-slot:title>
      <span v-if="!isEditMode">Create new</span> Table instruction
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <b-field label="Question" horizontal class="full-width">
          <b-input v-model="question" :disabled="loading"></b-input>
        </b-field>
        <div class="table-container">
          <b-field v-if="!imageSource">
            <b-upload v-model="dropFiles" drag-drop @input="onUploadImage">
              <section class="section">
                <div class="content has-text-centered">
                  <p v-if="!loadingImage">
                    <b-icon icon="upload" size="is-medium"> </b-icon>
                  </p>
                  <p v-if="!loadingImage">Upload diagram</p>
                  <p v-else>Uploading...</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <b-field v-else class="full-width">
            <b-upload v-model="dropFiles" drag-drop @input="onUploadImage">
              <img :src="imageSource" alt="Table image" />
            </b-upload>
          </b-field>
          <b-button
            v-if="imageSource"
            type="is-danger"
            icon-right="delete"
            @click="deleteImage"
          />
        </div>

        <b-field
          class="full-width"
          style="display: flex; flex-direction: column"
        >
          <div class="slider">
            <span style="width: 120px">Rows: {{ rows }}</span>
            <b-slider
              size="is-medium"
              v-model="rows"
              :min="2"
              :max="50"
            ></b-slider>
          </div>
          <div class="slider">
            <span style="width: 120px">Columns: {{ cols }}</span>
            <b-slider
              size="is-medium"
              v-model="cols"
              :min="1"
              :max="20"
            ></b-slider>
          </div>
        </b-field>
        <TableQuestionLayout
          ref="tableQuestionLayout"
          v-if="tableData"
          :rows="rows"
          :cols="cols"
          :data="instruction?.tableData || []"
          displayType="partialEdit"
          @tableDataChanged="onTableDataChanged"
        />

        <b-field>
          <b-checkbox v-model="pictureMandatory">
            Picture taken is mandatory.</b-checkbox
          >
        </b-field>
        <b-checkbox v-model="useImageReference" v-if="imageSource"
          >Use image as task reference</b-checkbox
        >
      </div>
    </template>
    <template v-slot:footer>
      <div class="table-question-footer">
        <b-button
          :disabled="!instruction"
          type="is-danger"
          @click="$emit('onDelete')"
          >Delete</b-button
        >
        <b-button type="is-link" @click="$emit('onCancel')">Cancel</b-button>
        <b-button
          v-if="!instruction"
          type="is-success"
          :disabled="loading"
          @click="onSave"
          >Save</b-button
        >
        <b-button v-if="instruction" type="is-success" @click="onUpdate"
          >Update</b-button
        >
      </div>
    </template>
  </Layout>
</template>

<script>
import { v4 } from 'uuid';
import TableQuestionLayout from '../TableQuestionLayout';
import Layout from './layout';
import { GET_CURRENT_USER } from '../../../../../store/users/getters/getterTypes';
import { GET_CURRENT_TASK } from '../../../../../store/tasks/getters/getterTypes';
import DIJVueConfigurations from '../../../../../helpers/DIJVueConfigurations';
import { GET_LOADING_STATE } from '../../../../../store/loading/getters/getterTypes';
import KeyHandlerMixin from '../../../../../mixins/KeyHandlerMixin';
import { UPDATE_QUESTION } from '../../../../../store/questions/actions/actionTypes';
import onlineService from '../../../../../services/onlineService';

const uuid = v4;

export default {
  name: 'tablequestion',
  components: {
    Layout,
    TableQuestionLayout,
  },
  mixins: [KeyHandlerMixin],
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    instruction: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.isEditFirstColumnMode = false;

    if (this.instruction) {
      this.question = this.instruction.question;
      this.pictureMandatory = this.instruction.isPictureMandatory;
      this.rows = this.instruction.tableRow;
      this.cols = this.instruction.tableColumn;
      this.tableData = this.instruction.tableData;
      this.fileUrl = this.instruction.tableImg;
      if (
        this.instruction &&
        this.task.thumbnailUrl &&
        this.instruction.tableImg &&
        this.task.thumbnailUrl === this.instruction.tableImg
      ) {
        this.useImageReference = true;
      } else {
        this.useImageReference = false;
      }
    }
    this.onUpdateCallback = {
      callback: this.onUpdate,
      isUpdate: this.instruction,
    };
    this.onSaveCallback = {
      callback: this.onSave,
      isSave: !this.instruction,
    };
  },
  watch: {
    instruction(newVal) {
      if (newVal) {
        this.question = newVal.question;
      }
    },
    useImageReference(val) {
      const currentTask = this.task;
      const thumbnailUrl = this.fileUrl;
      if (val) {
        this.$store.dispatch('updateTask', { ...currentTask, thumbnailUrl });
      } else {
        this.$store.dispatch('updateTask', {
          ...currentTask,
          thumbnailUrl: null,
        });
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters[GET_CURRENT_USER];
    },
    task() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    imageSource() {
      // For local env we need to point to the local s3
      if (this.fileUrl && window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${this.fileUrl}`;
      }

      return this.fileUrl;
    },
    loading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
  },
  data() {
    return {
      tableData: [],
      question: '',
      pictureMandatory: false,
      rows: 3,
      cols: 2,
      dropFiles: null,
      fileUrl: '',
      loadingImage: false,
      useImageReference: false,
      offlineId: undefined,
    };
  },
  methods: {
    onUploadImage(image) {
      if (this.loadingImage) {
        return;
      }

      this.saveImageToS3({ file: image });
    },
    async saveImageToS3(data) {
      const site = this.user.location;
      const path = `uploads/sites/${site}/${new Date().getFullYear()}/${
        this.task._id
      }`;
      this.loadingImage = true;

      let uploadedUrl;
      if (await onlineService.getStatus()) {
        uploadedUrl = await this.uploadToS3(data.file, path);
      } else {
        let questionId = uuid;

        if (this.instruction && this.instruction._id) {
          questionId = this.instruction._id;
        } else {
          this.offlineId = questionId;
        }

        const dijConfigurations = new DIJVueConfigurations();
        uploadedUrl = await dijConfigurations.offlineUploadFileToS3(
          data.file,
          data.file.name,
          path,
          'question',
          questionId
        );
      }

      setTimeout(() => {
        this.fileUrl = uploadedUrl;
        this.loadingImage = false;
      }, 4500);
    },
    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      const url = await dijConfigurations.uploadFileToS3(file, file.name, path);
      return url;
    },
    onTableDataChanged(data) {
      this.tableData = data;
    },
    onSave() {
      if (this.$refs.tableQuestionLayout.isEditFirstColumnEditable) {
        this.$refs.tableQuestionLayout.toggleEditMode();
        this.tableData = this.$refs.tableQuestionLayout.tempTableData;
      }

      if (this.offlineId) {
        this.question._id = this.offlineId;
      }

      this.$emit('onSave', {
        question: this.question,
        isPictureMandatory: this.pictureMandatory,
        type: 'tablequestion',
        description: `Table ${this.rows} x ${this.cols}`,
        tableData: this.tableData,
        tableRow: this.rows,
        tableColumn: this.cols,
        dimensions: `${this.rows} X ${this.cols}`,
        tableImg: this.fileUrl,
      });
    },
    onUpdate() {
      if (this.$refs.tableQuestionLayout.isEditFirstColumnEditable) {
        this.$refs.tableQuestionLayout.toggleEditMode();
        this.tableData = this.$refs.tableQuestionLayout.tempTableData;
      }

      this.$emit('onUpdate', {
        _id: this.instruction._id,
        ...this.instruction,
        question: this.question,
        isPictureMandatory: this.pictureMandatory,
        type: 'tablequestion',
        description: `Table ${this.rows} x ${this.cols}`,
        tableData: this.tableData,
        tableRow: this.rows,
        tableColumn: this.cols,
        dimensions: `${this.rows} X ${this.cols}`,
        tableImg: this.fileUrl,
      });
    },
    async deleteImage() {
      this.fileUrl = null;
      await this.$store.dispatch(UPDATE_QUESTION, {
        tableImg: null,
        _id: this.instruction._id,
      });

      if (this.task.thumbnailUrl === this.instruction.tableImg) {
        const currentTask = this.task;
        this.$store.dispatch('updateTask', {
          ...currentTask,
          thumbnailUrl: null,
        });
      }
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
}

.slider {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.full-width {
  width: 100%;
}

button {
  flex: 1;
}

.content-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 60px;
  padding-bottom: 30px;
}

.table-question-layout {
  margin: 0 10%;
  min-height: 100%;
}

.table-question-footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 75%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
</style>
