// eslint-disable-next-line import/no-extraneous-dependencies
import { Amplify } from 'aws-amplify';

console.log('AWS Amplify config initialized');

const REGION = 'eu-central-1';

// eslint-disable-next-line no-unused-vars
const EVENT_URL =
  'https://6gb3unnkwzh2ribxssrmimo46a.appsync-api.eu-central-1.amazonaws.com/event';
// eslint-disable-next-line no-unused-vars
const API_KEY = 'da2-gpzwexnvhvc3pcmyrfczpdipnu';

const EVENT_UAT_URL =
  'https://gcxacglftjavvaixd2gaqexv6m.appsync-api.eu-central-1.amazonaws.com/event';
const API_KEY_UAT = 'da2-hn4rtgkevbfddobbaqqo5r7zgm';

Amplify.configure({
  API: {
    Events: {
      endpoint: EVENT_UAT_URL,
      region: REGION,
      defaultAuthMode: 'apiKey',
      apiKey: API_KEY_UAT,
    },
  },
});
