// Generic handlers

import {
  createRecord,
  deleteRecord,
  getRecord,
  markSyncRecordForDeletion,
  updateRecord,
} from '../services/db';

const baseGet = (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = splittedUrl[1];
  const recordId = splittedUrl[2];
  return getRecord(recordType, recordId);
};

const basePost = (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = splittedUrl[1];
  const recordId = splittedUrl[2];
  return createRecord(recordType, { ...args[1], _id: recordId });
};

const basePut = (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = splittedUrl[1];
  const recordId = splittedUrl[2];
  return updateRecord(recordType, { ...args[1], _id: recordId });
};

const basePatch = (...args) => {
  return basePut(args);
};

const baseDelete = (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordType = splittedUrl[1];
  const recordId = splittedUrl[2];
  return deleteRecord(recordType, recordId);
};

const baseMarkForDelete = (...args) => {
  const url = args[0];
  const splittedUrl = url.split('/');
  const recordId = splittedUrl[2];
  return markSyncRecordForDeletion(recordId);
};

const getQueryParameters = (url) => {
  const rawParameters = url.substring(url.indexOf('?') + 1); // +1 to exclude the '?' character
  const rawParametersSplitted = rawParameters.split('&');
  return rawParametersSplitted.reduce((accumulator, rawParameter) => {
    const rawParameterSplitted = rawParameter.split('=');
    if (rawParameterSplitted.length === 2) {
      const [key, value] = rawParameterSplitted;
      accumulator[key] = value;
    }
    return accumulator; // Ensure the accumulator is returned
  }, {});
};

const concatRegex = (...expressions) => {
  const allFlags = expressions.reduce(
    (accumulator, expression) => accumulator.concat(expression.flags),
    ''
  );
  const uniqueFlags = Array.from(new Set(allFlags.split(''))).join();
  const allSources = expressions.reduce(
    (accumulator, expression) => accumulator.concat(expression.source),
    ''
  );
  return new RegExp(allSources, uniqueFlags);
};

const extractUrlSegment = (url, segmentIndex) => {
  try {
    // Ensure the URL is absolute for parsing
    const baseUrl = 'http://localhost'; // Dummy base URL
    const parsedUrl = url.startsWith('http')
      ? new URL(url)
      : new URL(url, baseUrl);

    const pathSegments = parsedUrl.pathname.split('/').filter(Boolean); // Removes empty segments

    return pathSegments[segmentIndex] || null; // Return segment or null if out of bounds
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

const matchRoute = (method, url, handlers) => {
  const segments = url.split('/').filter(Boolean);

  const matchedRoute = Object.entries(handlers).find(([pattern]) => {
    const [routeMethod, ...patternSegments] = pattern.split(' ');

    const patternSegmentsSplitted = patternSegments[0]
      .split('/')
      .filter(Boolean);
    return (
      routeMethod === method &&
      patternSegmentsSplitted.length === segments.length &&
      patternSegmentsSplitted.every(
        (segment, i) => segment.startsWith(':') || segment === segments[i]
      )
    );
  });

  if (!matchedRoute) {
    throw new Error(`Failed to handle ${method} ${url}`);
  }

  const [pattern, handler] = matchedRoute;
  const [, ...patternSegments] = pattern.split(' ');

  const patternSegmentsSplitted = patternSegments[0].split('/').filter(Boolean);
  const params = patternSegmentsSplitted.reduce((acc, segment, i) => {
    if (segment.startsWith(':')) acc[segment.slice(1)] = segments[i];
    return acc;
  }, {});

  return { handler, params, url };
};

const uuidRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}';

export {
  baseGet,
  basePost,
  basePut,
  basePatch,
  baseDelete,
  baseMarkForDelete,
  getQueryParameters,
  concatRegex,
  extractUrlSegment,
  matchRoute,
  uuidRegex,
};
