<template>
  <div id="container-findings">
    <div id="title">FINDINGS</div>
    <b-field label="By Inspector" :class="'inspector-input'">
      <b-input type="textarea" readonly v-model="task.taskComment"></b-input>
    </b-field>
    <b-field label="By Reporter" :class="'inspector-input'">
      <b-input
        type="textarea"
        v-model="task.reportNote"
        v-on:blur="onUpdateJobReport"
        placeholder="Please enter your findings here..."
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    taskProp: {
      type: Object,
      required: true,
    },
  },
  name: 'Findings',
  computed: {
    task() {
      return this.taskProp;
    },
  },
  data() {
    return { persistedReportNode: null };
  },
  created() {
    this.persistedReportNode = this.task.reportNote;
  },
  methods: {
    async onUpdateJobReport() {
      await this.$store.dispatch('updateTaskReportNote', {
        taskId: this.task._id,
        reportNote: this.task.reportNote,
      });
      this.persistedReportNode = this.task.reportNote;
    },
  },
};
</script>

<style lang="scss" scoped>
#container-findings {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  padding: 10px;
}
#title {
  font-family: 'Siemens Sans';
  font-size: 18px;
}

.inspector-input {
  margin-top: 30px;
}
</style>
