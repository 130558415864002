// eslint-disable-next-line import/no-extraneous-dependencies
import { events } from 'aws-amplify/data';

export default {
  async connectToChannel(channelName) {
    try {
      const channel = await events.connect(channelName);
      console.log('Connected to channel:', channelName);

      channel.subscribe({
        next: (data) => {
          console.log('Received event:', data);
        },
        error: (err) => console.error('Subscription error:', err),
      });

      return channel;
    } catch (error) {
      console.error('Error connecting to channel:', error);
    }
  },

  async postEvent(channelName, eventData) {
    try {
      await events.post(channelName, eventData);
      console.log('Event posted:', eventData);
    } catch (error) {
      console.error('Error posting event:', error);
    }
  },
};
