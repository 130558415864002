<template>
  <div class="content-type">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
      <slot name="reference-image"></slot>
    </div>

    <div class="content-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout',
};
</script>

<style scoped>
.content-type {
  background: white;
  border-radius: 10px;
  padding: 30px 10px 10px 10px;
  margin: 0 25%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: x-large;
  font-weight: bold;
}

.content-footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
