<template>
  <Layout class="table-question-layout" :instruction="instruction">
    <b-loading
      :is-full-page="false"
      :active.sync="loading"
      :can-cancel="false"
    ></b-loading>
    <template v-slot:title>
      <div>
        <span v-if="isEditMode">Edit</span> Import CSV
        <span v-if="isEditMode">table</span>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="!isEditMode" class="content-wrapper">
        <b-field label="Title" horizontal class="full-width">
          <b-input v-model="question"></b-input>
        </b-field>
        <input
          type="file"
          @change="onFileChange"
          accept=".csv"
          class="full-width"
        />
        Note: The headings of the columns should be on the first line of the CSV
        file. The import only works for CSV file, not Excel file.
      </div>
      <div v-else class="content-wrapper">
        <b-field label="Question" horizontal class="full-width">
          <b-input v-model="question"></b-input>
        </b-field>

        <b-field
          class="full-width"
          style="display: flex; flex-direction: column"
        >
          <div class="slider">
            <span style="width: 120px">Rows: {{ rows }}</span>
            <b-slider
              size="is-medium"
              v-model="rows"
              :min="2"
              :max="50"
            ></b-slider>
          </div>
          <div class="slider">
            <span style="width: 120px">Columns: {{ cols }}</span>
            <b-slider
              size="is-medium"
              v-model="cols"
              :min="1"
              :max="20"
            ></b-slider>
          </div>
        </b-field>
        <TableQuestionLayout
          ref="tableQuestionLayout"
          v-if="initialized"
          :rows="rows"
          :cols="cols"
          :data="instruction?.tableData || []"
          displayType="partialEdit"
          @tableDataChanged="onTableDataChanged"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div
        :class="{
          'table-question-footer': isEditMode,
          'import-csv': !isEditMode,
        }"
      >
        <b-button
          :disabled="!instruction"
          type="is-danger"
          @click="$emit('onDelete')"
          >Delete</b-button
        >
        <b-button type="is-link" @click="$emit('onCancel')">Cancel</b-button>
        <b-button
          v-if="!instruction"
          type="is-success"
          :disabled="loading || tableData.length === 0"
          @click="onImport"
          >Import</b-button
        >
        <b-button v-if="instruction" type="is-success" @click="onUpdate"
          >Update</b-button
        >
      </div>
    </template>
  </Layout>
</template>

<script>
import * as XLSX from 'xlsx';

import TableQuestionLayout from '../TableQuestionLayout';
import Layout from './layout';
import { GET_CURRENT_USER } from '../../../../../store/users/getters/getterTypes';
import { GET_CURRENT_TASK } from '../../../../../store/tasks/getters/getterTypes';
import { GET_LOADING_STATE } from '../../../../../store/loading/getters/getterTypes';

export default {
  name: 'tablequestion',
  components: {
    Layout,
    TableQuestionLayout,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    instruction: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.instruction) {
      this.question = this.instruction.question;
      this.rows = this.instruction.tableRow;
      this.cols = this.instruction.tableColumn;
      this.tableData = this.instruction.tableData;
      this.initialized = true;
    }
  },
  watch: {
    instruction(newVal) {
      if (newVal) {
        this.question = newVal.question;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters[GET_CURRENT_USER];
    },
    task() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    loading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
  },
  data() {
    return {
      tableData: [],
      question: '',
      rows: 3,
      cols: 2,
      initialized: false,
    };
  },
  methods: {
    onFileChange(event) {
      const { files } = event.target;
      if (!files || files.length === 0) return;

      const file = files[0];

      // Check if the file is a CSV by MIME type
      if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
        this.$buefy.snackbar.open({
          message: 'Please upload only .csv files',
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        return; // Stop processing any further
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.tableData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Set the first cell of the first row to an empty string
        // To enable edit button
        if (this.tableData.length > 0 && this.tableData[0].length > 0) {
          this.tableData[0][0] = '';
        }

        this.rows = this.tableData.length;
        this.cols = this.tableData.length > 0 ? this.tableData[0].length : 0;
        this.question = file.name;
      };

      reader.onerror = (err) => {
        console.error('Error reading file:', err);
      };

      reader.readAsArrayBuffer(file);
    },
    onTableDataChanged(data) {
      this.tableData = data;
    },
    onImport() {
      this.$emit('onSave', {
        question: this.question,
        type: 'importcsv',
        description: `Table ${this.rows} x ${this.cols}`,
        tableData: this.tableData,
        tableRow: this.rows,
        tableColumn: this.cols,
        dimensions: `${this.rows} X ${this.cols}`,
      });
    },
    onUpdate() {
      if (this.$refs.tableQuestionLayout.isEditFirstColumnEditable) {
        this.$refs.tableQuestionLayout.toggleEditMode();
        this.tableData = this.$refs.tableQuestionLayout.tempTableData;
      }

      this.$emit('onUpdate', {
        _id: this.instruction._id,
        ...this.instruction,
        question: this.question,
        type: 'importcsv',
        description: `Table ${this.rows} x ${this.cols}`,
        tableData: this.tableData,
        tableRow: this.rows,
        tableColumn: this.cols,
        dimensions: `${this.rows} X ${this.cols}`,
      });
    },
  },
};
</script>

<style scoped>
.table-container {
  margin-left: 25%;
  width: 735px;
}

.slider {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: x-large;
}

.full-width {
  width: 100%;
}

button {
  flex: 1;
}

.content-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 60px;
  padding-bottom: 30px;
}

.table-question-layout {
  margin: 0 10%;
  min-height: 100%;
}

.import-csv {
  width: 100%;
  gap: 20px;
  display: flex;
}

.table-question-footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 75%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  background: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
</style>
