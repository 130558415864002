<template>
  <VueDraggableResizable
    v-if="isVisible || forcePopup"
    :w="400"
    :h="600"
    :x="this.position.x"
    :y="this.position.y"
    :draggable="isDragging"
    style="position: absolute; z-index: 9999"
  >
    <transition name="fade">
      <div class="dialog-container">
        <div
          class="dialog"
          :style="{ top: `${position.y}px`, left: `${position.x}px` }"
        >
          <ChatHeader
            :title="'Ralph-i'"
            @close="closeChatBox"
            @mousedown.native="startDrag"
            @mouseup.native="stopDrag"
          />
          <ChatBody
            :messages="messages"
            :style="{ opacity: showLoading ? '0.3' : '1' }"
          />

          <SpinningWheel v-if="showLoading" />
          <ChatFooter @send="addMessage" />
        </div>
      </div>
    </transition>
  </VueDraggableResizable>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VueDraggableResizable from 'vue-draggable-resizable';
// eslint-disable-next-line import/no-unresolved
import { v4 } from 'uuid';

import ChatHeader from './components/ChatHeader';
import ChatBody from './components/ChatBody';
import ChatFooter from './components/ChatFooter';
import LoadingBar from './components/LoadingBar';
import SpinningWheel from './components/SpinningWheel';
import amplifyEventService from '../../services/aws-amplify/amplifyEvent';
import { SEND_REPAIR_SELECT_REQUEST } from '../../store/repairselect/actions/actionTypes';

const uuidv4 = v4;

export default {
  components: {
    ChatHeader,
    ChatBody,
    ChatFooter,
    LoadingBar,
    VueDraggableResizable,
    SpinningWheel,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Chat Box',
    },
    message: {
      type: String,
      default: 'How can I help you?',
    },
    chatboxMetadata: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      isDragging: false,
      position: { x: window.innerWidth - 520, y: window.innerHeight - 720 },
      offset: { x: 0, y: 0 },
      messages: [],
      showLoading: true,
      channel: null,
      chatId: null,
      storeInjection: null,
      messageTimeout: null,
      forcePopup: false,
    };
  },
  watch: {
    async isVisible(visible) {
      if (visible) {
        this.handleVisibilityChange();
      }
    },
    message(newMessage) {
      this.messages = [];
      this.showLoading = true;

      this.messages.push({ text: newMessage, isUser: false });

      // setTimeout(() => {
      //   this.messages.push({
      //     text: 'This message can be copied into the clipboard!',
      //     isUser: false,
      //     canBeCopied: true,
      //   });

      //   this.messages.push({
      //     text: 'This message contain options',
      //     isUser: false,
      //     canBeCopied: true,
      //     options: ['Option 1', 'Option 2', 'Option 3'],
      //   });

      //   this.showLoading = false;
      // }, 1000);
    },
  },
  async mounted() {
    this.channel = await amplifyEventService.connectToChannel('/dij/*');

    this.chatId = uuidv4();

    this.channel.subscribe({
      next: (data) => {
        clearTimeout(this.messageTimeout);

        console.log('Received:', data);
        if (data.event.provided_id === this.chatId) {
          if (data.event.status_code && data.event.status_code === 500) {
            this.addSystemMessage(
              'An error has occured. Please try again later.'
            );
          } else {
            if (!this.isVisible) {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Executive summary has been generated!',
                type: 'is-success',
                position: 'is-bottom',
              });

              setTimeout(() => {
                this.forcePopup = true;
              }, 1000);
            }
            this.messages.push({
              text: `${data.event.result}`,
              isUser: false,
              canBeCopied: true,
            });
          }
        }

        this.toggleLoading(false);
      },
      error: (err) => console.error('Subscription error:', err),
    });

    // Select all elements with the 'handle' class and set opacity to 0
    this.$nextTick(() => {
      const handles = document.querySelectorAll('.handle');
      handles.forEach((handle) => {
        handle.style.opacity = '0';
        handle.style.width = '25px';
        handle.style.height = '25px';
      });
    });
    console.log('mounted');
  },
  methods: {
    toggleLoading(state) {
      this.showLoading = state;
    },
    createPayload() {
      return {
        provided_id: this.chatId,
        request_type: 'executive_summary',
        data: JSON.stringify({
          repairAssessment: this.chatboxMetadata,
        }),
      };
    },
    async handleVisibilityChange() {
      this.initializeDialogPosition();
      this.sendRequest();
    },
    async sendRequest() {
      try {
        this.toggleLoading(true);

        const payload = this.createPayload();
        await this.$store.dispatch(SEND_REPAIR_SELECT_REQUEST, payload);

        this.messageTimeout = setTimeout(() => {
          if (this.showLoading) {
            this.toggleLoading(false);
            this.addSystemMessage(
              'The system didn’t respond in time. You’ll be notified once the result is available.'
            );
          }
        }, 60000);
      } catch (error) {
        this.toggleLoading(false);
        console.error('Error sending message:', error);
        this.addSystemMessage('An error occurred while processing the data');
      }
    },

    onCompleted() {
      this.showLoading = false;
    },
    closeChatBox() {
      this.$emit('close');
      this.forcePopup = false;
    },
    initializeDialogPosition() {
      this.position.x = window.innerWidth - 520;
      this.position.y = window.innerHeight - 720;
    },
    startDrag(event) {
      const dragIcon = event.target.closest('.drag-icon');
      if (dragIcon) {
        this.isDragging = true;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
    addMessage(newMessage) {
      this.messages.push({ text: newMessage, isUser: true });
      this.parseMessage(newMessage);
    },
    parseMessage(message) {
      if (
        typeof message === 'string' &&
        message.toLowerCase().includes('generate again')
      ) {
        this.addSystemMessage(
          'I will try to generate again the executive summary.'
        );
        setTimeout(() => {
          this.sendRequest();
        }, 1000);
      }
    },
    addSystemMessage(text) {
      this.messages.push({ text, isUser: false });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.dialog-container {
  width: 100%;
  height: 100%;
}

.dialog {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensures it's on top of other elements */
  pointer-events: auto; /* Allows interaction with the dialog */
}
</style>
