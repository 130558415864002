import client from '../../../config/api';
import {
  LOCAL_SET_BUBBLES,
  LOCAL_REMOVE_BUBBLE,
  LOCAL_UPDATE_BUBBLE,
} from '../mutations/mutationTypes';

import { GET_TASK } from '../../tasks/actions/actionTypes';
import { getRecord } from '../../../services/db';

const getPath = (location, jobName, taskId) => {
  const site = location.substring(0, 3);
  return `uploads/sites/${site}/${jobName}/${taskId}`;
};

const handleBubbleFiles = async (dispatch, bubble, path) => {
  const uploadFilePromises = [];

  if (bubble.images) {
    bubble.images.forEach((image, index) => {
      const isLocal = image.url.startsWith('data:');
      if (isLocal) {
        const uploadFilePromise = fetch(image.url)
          .then((fetchedUrl) => fetchedUrl.blob())
          .then((fetchedBlob) =>
            dispatch(
              'uploadFile',
              {
                file: fetchedBlob,
                path,
              },
              { root: true }
            )
          )
          .then((uploadUrl) => {
            bubble.images[index] = {
              name: image.name,
              description: image.description,
              url: uploadUrl,
            };
          });
        uploadFilePromises.push(uploadFilePromise);
      }
    });
  }

  return Promise.all(uploadFilePromises);
};

const handleBubblesFiles = async (dispatch, bubbles, path) => {
  const uploadFilesPromises = [];
  bubbles.forEach((bubble) => {
    uploadFilesPromises.push(handleBubbleFiles(dispatch, bubble, path));
  });

  return Promise.all(uploadFilesPromises);
};

export default {
  async SAVE_BUBBLES({ dispatch, rootState }, payload) {
    const { jobName, taskId, data } = payload;

    await handleBubblesFiles(
      dispatch,
      data,
      getPath(rootState.currentUser.location, jobName, taskId)
    );
    return client.post(`/bubbles/${taskId}`, data);
  },
  async GET_BUBBLES({ commit }, taskId) {
    const result = await client.get(`/bubbles/${taskId}`);
    commit(LOCAL_SET_BUBBLES, result.data);
  },
  async DELETE_BUBBLE({ commit }, bubbleId) {
    if (!bubbleId) {
      return;
    }

    await client.delete(`/bubble/${bubbleId}`);
    commit(LOCAL_REMOVE_BUBBLE, bubbleId);
  },
  async CREATE_BUBBLE({ dispatch, rootState }, data) {
    const { taskId, bubble, jobName } = data;
    if (!taskId || !bubble) {
      return;
    }

    await handleBubbleFiles(
      dispatch,
      data,
      getPath(rootState.currentUser.location, jobName, taskId)
    );

    const taskOfBubble = await getRecord('task', taskId);
    if (taskOfBubble) {
      const jobOfTask = await getRecord('job', taskOfBubble.jobId);

      if (jobOfTask && jobOfTask.checkedOut && jobOfTask.checkedOut !== '') {
        bubble.checkedJobId = jobOfTask._id;
      }
    }

    const response = await client.post(`/bubble/${taskId}`, bubble);
    return response.data;
  },
  async UPDATE_BUBBLE({ commit, dispatch, rootState }, bubbleData) {
    const { _id, taskId, jobName } = bubbleData;
    if (!_id) {
      console.warn('UPDATE_BUBBLE: Missing _id in bubbleData');
      return { error: 'Invalid bubble data', status: 400 };
    }

    try {
      await handleBubbleFiles(
        dispatch,
        bubbleData,
        getPath(rootState.currentUser.location, jobName, taskId)
      );

      const result = await client.put(`/bubble/${_id}`, bubbleData);
      commit(LOCAL_UPDATE_BUBBLE, bubbleData);

      if (taskId) {
        await dispatch(GET_TASK, taskId, { root: true });
      }

      return { success: true, status: 200, data: result.data };
    } catch (error) {
      console.error('UPDATE_BUBBLE failed:', error);

      const response = {
        error: error.message || 'An unexpected error occurred',
        status: error.status || 500,
      };

      // Special handling for 409 Conflict (Optimistic Locking)
      if (error.status === 409) {
        response.message =
          error.message ||
          'Data conflict detected. Another user has modified this data.';
        response.persistedData = error.persistedData || null;
        response.attemptedData = error.attemptedData || null;
      }

      return response;
    }
  },
};
