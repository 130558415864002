import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const bubblesModule = {
  namespaced: true,
  state() {
    return { bubbles: [], synchronizedBubbles: [] };
  },
  actions,
  getters,
  mutations,
};

export default bubblesModule;
