// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { acquireToken } from '../services/auth';
import {
  cacheData,
  mapApiToDbEntity,
  offlineDelete,
  offlineGet,
  offlinePatch,
  offlinePost,
  offlinePut,
} from '../services/offlineApiHandler';

import { DELETE_RECORD_FROM_DB } from '../store/offline/mutations/mutationTypes';
import onlineService from '../services/onlineService';
// eslint-disable-next-line import/no-cycle

const apiHost = process.env.VUE_APP_API_ENDPOINT;

const client = axios.create({
  baseURL: `${apiHost}/dij`,
});

client.injectStore = (store) => {
  client.store = store;
};

const onlineGet = client.get;
const onlinePost = client.post;
const onlinePut = client.put;
const onlinePatch = client.patch;
const onlineDelete = client.delete;

client.get = async (...args) => {
  if (await onlineService.getStatus()) {
    const resultPromise = onlineGet(...args);
    await cacheData(resultPromise, ...args);
    return resultPromise;
  }
  return offlineGet(...args);
};

client.post = async (...args) => {
  if (await onlineService.getStatus()) {
    return onlinePost(...args);
  }
  return offlinePost(...args);
};

client.put = async (...args) => {
  if (await onlineService.getStatus()) {
    return onlinePut(...args);
  }
  return offlinePut(...args);
};

client.patch = async (...args) => {
  if (await onlineService.getStatus()) {
    return onlinePatch(...args);
  }
  return offlinePatch(...args);
};

client.delete = async (...args) => {
  const deleteOperation = (await onlineService.getStatus())
    ? onlineDelete(...args)
    : offlineDelete(...args);

  return deleteOperation
    .then((response) => {
      if (response.status === 200) {
        const url = args[0];
        const splittedUrl = url.split('/');
        const recordType = mapApiToDbEntity(splittedUrl[1]);
        client.store.commit(DELETE_RECORD_FROM_DB, {
          recordType,
          recordId: splittedUrl[2],
        });
      }
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

client.interceptors.request.use(
  async (config) => {
    const accessToken = await acquireToken();

    // urls to not check
    const urls = ['signin', 'callback'];
    const checkToken = !urls.some((url) => document.URL.includes(url));

    const refreshPageEvent = new CustomEvent('showRefreshMessage');

    // check if token is valid

    // invalid token
    if (checkToken && (!accessToken || Object.keys(accessToken).length < 1)) {
      window.dispatchEvent(refreshPageEvent);

      return Promise.reject(new Error('Invalid token'));
    }

    const tokenData = jwt_decode(accessToken);

    if (checkToken && Date.now() >= tokenData.exp * 1000) {
      console.error('Access token expired');
      window.dispatchEvent(refreshPageEvent);

      return Promise.reject(new Error('Access token expired'));
    }
    config.headers.authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => {
    // Handle other types of errors that occur during the request
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const modifiedError = new Error(error.message || 'An error occurred');

    modifiedError.status = error.response?.status || 500;
    modifiedError.message =
      error.response?.data?.message || error.message || 'An error occurred';
    modifiedError.data = error.response?.data?.data || null;

    // Special handling for 409 Conflict (Optimistic Locking)
    if (error.response?.status === 409) {
      console.log('CONFLICTTT');
      console.log('Persisted Data:', error.response.data.persistedData);
      console.log('Attempted Data:', error.response.data.attemptedData);
      modifiedError.persistedData = error.response?.data?.persistedData || null;
      modifiedError.attemptedData = error.response?.data?.attemptedData || null;
    }

    return Promise.reject(modifiedError);
  }
);

export default client;
