import client from '../../../config/api';
import {
  LOCAL_SET_CURRENT_TASK,
  LOCAL_SET_CURRENT_FOLDER,
} from '../mutations/mutationTypes';

const getPath = (taskId) => {
  return `uploads/products/${new Date().getFullYear()}/${taskId}`;
};

const handleFiles = async (dispatch, task, path) => {
  if (task.diagram && task.diagram.src) {
    const image = task.diagram.src;
    const isLocal = image.startsWith('data:');
    if (isLocal) {
      return fetch(image)
        .then((fetchedUrl) => fetchedUrl.blob())
        .then((fetchedBlob) =>
          dispatch(
            'uploadFile',
            {
              file: fetchedBlob,
              path,
            },
            { root: true }
          )
        )
        .then((uploadUrl) => {
          task.diagram.src = uploadUrl;
        });
    }
  }

  return '';
};

export default {
  async GET_TASK({ commit }, id) {
    const result = await client.get(`/task/${id}`);
    commit(LOCAL_SET_CURRENT_TASK, result.data);
  },

  async GET_FOLDER(_1, id) {
    const result = await client.get(`/task/${id}`);
    return result.data;
  },

  async DELETE_TASK(_1, id) {
    await client.delete(`/task/${id}`);
  },

  async SAVE_TASK({ commit, dispatch }, data) {
    await handleFiles(dispatch, data, getPath(data._id));

    await client.put('/task/save/new', data);

    // infer the version number
    data.version =
      data.version === undefined || data.version === null
        ? 0
        : data.version + 1;

    commit(LOCAL_SET_CURRENT_TASK, data);
  },

  async SIGN_LEADMAN(_1, data) {
    const { id, isSign } = data;
    await client.post(`/task/signleadman/${id}`, { isSign });
  },

  async SAVE_CURRENT_TASK_STATE({ state }) {
    await client.put('/task/save/new', state.currentTask);
  },

  async GET_FOLDER_DATA({ commit }, id) {
    const result = await client.get(`/task/${id}`);
    commit(LOCAL_SET_CURRENT_FOLDER, result.data);
  },
};
