// eslint-disable-next-line import/no-extraneous-dependencies
import { register } from 'register-service-worker';
import onlineService from './services/onlineService';

if ('serviceWorker' in navigator) {
  if (await onlineService.getStatus()) {
    register('/sw.js', {
      ready() {
        console.log('Service worker is active.');
      },
      registered() {
        console.log('Service worker has been registered.');
      },
      cached() {
        console.log('Content has been cached for offline use.');
      },
      updatefound() {
        console.log('Removing old content.');
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        });
        console.log('New content is downloading.');
      },
      updated() {
        console.log('New content is available; please refresh.');
      },
      offline() {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });
  } else {
    console.warn('Offline: Skipping service worker registration');
    navigator.serviceWorker.ready
      .then((registration) => {
        console.log(
          'Service worker is already installed and running offline.',
          registration
        );
      })
      .catch(() => {
        console.warn(
          'No active service worker found, and cannot register while offline.'
        );
      });
  }
}
