/* eslint-disable no-else-return */
import {
  createRecord,
  deleteRecord,
  getRecord,
  getRecords,
  getRecordsUsingIndex,
  removeAttributesFromRecord,
  updateRecord,
} from '../services/db';
import {
  basePatch,
  basePost,
  extractUrlSegment,
  getQueryParameters,
  matchRoute,
  uuidRegex,
} from './base';
import { clearBubbleMeasurements } from './bubble';
import { clearQuestionsMeasurements } from './question';

const handlers = {
  // PUT Handlers
  'PUT job/:jobId': async ({ jobId }, body) =>
    updateRecord('job', { ...body, _id: jobId }),

  'PUT job/:jobId/favoritecount': async ({ jobId }, body) => {
    updateRecord('job', { _id: jobId, favoriteCount: body.count });
    return { status: true, count: body.count };
  },

  'PUT job/:jobId/undelete': async ({ jobId }) => {
    const deletedTasks = await getRecordsUsingIndex(
      'task',
      'jobId',
      jobId
    ).then((tasks) => tasks.filter((task) => task.deleted));

    await Promise.all(
      deletedTasks.map((task) =>
        removeAttributesFromRecord('task', task._id, [
          'deleted',
          'deletedBy',
          'deletedAt',
          'folderId',
        ])
      )
    );

    await removeAttributesFromRecord('job', jobId, [
      'deleted',
      'deletedBy',
      'deletedAt',
    ]);

    return { status: true };
  },

  'PUT job/:jobId/ra/:rowId': async ({ jobId, rowId }, body) => {
    const job = await getRecord('job', jobId);
    const rowIndex = job.repairAssessment.findIndex((r) => r.id === rowId);
    if (rowIndex === -1)
      throw new Error(`Row ${rowId} not found in job ${jobId}`);

    job.repairAssessment[rowIndex] = body;
    updateRecord('job', { repairAssessment: job.repairAssessment, _id: jobId });

    return body;
  },

  'PUT job/:jobId/ra/:firstRowId/:secondRowId': async ({
    jobId,
    firstRowId,
    secondRowId,
  }) => {
    const job = await getRecord('job', jobId);
    const { repairAssessment } = job;

    const firstRowIndex = repairAssessment.findIndex(
      (r) => r.id === firstRowId
    );
    const secondRowIndex = repairAssessment.findIndex(
      (r) => r.id === secondRowId
    );

    if (firstRowIndex === -1 || secondRowIndex === -1) {
      throw new Error(`Invalid row IDs: ${firstRowId} or ${secondRowId}`);
    }

    const [rowToMove] = repairAssessment.splice(firstRowIndex, 1);
    repairAssessment.splice(secondRowIndex, 0, rowToMove);

    updateRecord('job', { repairAssessment, _id: jobId });

    return repairAssessment;
  },

  // POST Handlers
  'POST job': async (_, body, args) => basePost(args),
  'POST job/:jobId/ra/:rowId/lock': async () => null,
  'POST job/:jobId/ra/lock': async ({ jobId }) => {
    const job = await getRecord('job', jobId);

    return job.repairAssessment;
  },
  'POST job/:jobId/ra/unlock': async ({ jobId }) => {
    const job = await getRecord('job', jobId);

    return job.repairAssessment;
  },
  'POST job/:jobId/duplicate': async ({ jobId }, body) => {
    const job = await getRecord('job', jobId);

    Object.keys(body).forEach((key) => {
      job[key] = body[key];
    });

    job.approvalStatus = 'initial';
    job.isNew = true;

    if (job.type === 'workOrderLibrary') {
      job.type = 'workViewerLibrary';
    }

    const tasks = await getRecordsUsingIndex('task', 'jobId', jobId);
    const user = await getRecord('session', 'currentUser');

    await Promise.all(
      tasks.map(async (task) => {
        const originalTaskId = task._id;

        task.jobId = job._id;
        task.name = job.name;
        task.partNumber = job.partNumber;

        const duplicateTransactions = [];

        if (task.taskType === 'diagram') {
          const bubbles = await getRecordsUsingIndex(
            'bubble',
            'taskId',
            originalTaskId
          );
          bubbles.forEach((bubble) => {
            bubble.taskId = task._id;
            bubble.lastModifiedBy = user.fullname;
            if (task.isDuplicated) clearBubbleMeasurements(bubble);
            duplicateTransactions.push(createRecord('bubble', bubble));
          });
        } else if (task.taskType === 'instruction') {
          const questions = await getRecordsUsingIndex(
            'question',
            'taskId',
            originalTaskId
          );
          questions.forEach((question) => {
            question.taskId = task._id;
            question.lastModifiedBy = user.fullname;
            if (task.isDuplicated) clearQuestionsMeasurements(question);
            duplicateTransactions.push(createRecord('question', question));
          });
        }

        await Promise.all(duplicateTransactions);

        if (task.isDuplicated) {
          task.taskComment = '';
          task.completenessPercentage = 0;
          delete task.isDuplicated;
        }

        await createRecord('task', task);
      })
    );

    createRecord('job', job);
    return { newJob: job };
  },

  'POST job/:jobId/ra': async ({ jobId }, body, url) => {
    const job = await getRecord('job', jobId);
    const { replace, replaceMinor, rows } = body;

    if (replace) {
      job.repairAssessment = job.repairAssessment.filter((item) =>
        replaceMinor ? !item.isMinor : item.isMinor
      );
    }

    const newRows = Array.isArray(rows) ? rows : [rows];

    const nowDate = new Date();
    // Remove the timezone/DST offset to get UTC and convert to seconds
    const nowEpoch = Math.round(
      (nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000) / 1000
    );
    const user = await getRecord('session', 'user');
    const { tool } = getQueryParameters(url);

    if (!job.repairAssessment) {
      job.repairAssessment = [];
    }

    newRows.forEach((row) => {
      job.repairAssessment.push(row);
      if (tool === 'publisher') {
        row.originalAssembly = row.assembly;
        row.originalItemNumber = row.itemNumber;
        row.originalPartName = row.partName;
        row.originalPartNumber = row.partNumber;
        row.originalQuantity = row.quantity;
      }
      row.changeLog = [{ by: user.fullname, at: nowEpoch, tool }];
    });

    await updateRecord('job', { ...job, _id: jobId });
    return newRows.length === 1 ? newRows[0] : newRows;
  },

  'POST joblist': async () => {
    const jobs = await getRecords('job');
    return { jobs };
  },

  // DELETE Handlers
  'DELETE job/:jobId': async ({ jobId }) => {
    const tasksToBeDeleted = await getRecordsUsingIndex('task', 'jobId', jobId);
    const user = await getRecord('session', 'user');

    const nowDate = new Date();
    const nowEpoch = Math.round(
      (nowDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000) / 1000
    );

    await Promise.all(
      tasksToBeDeleted.map((task) =>
        updateRecord('task', {
          _id: task._id,
          deleted: true,
          deletedBy: user.fullname,
          deletedAt: nowEpoch,
        })
      )
    );

    await updateRecord('job', {
      _id: jobId,
      deleted: true,
      deletedBy: user.fullname,
      deletedAt: nowEpoch,
    });

    return { status: true };
  },

  'DELETE job/:jobId/ra/:rowId': async ({ jobId, rowId }, body) => {
    const job = await getRecord('job', jobId);
    const { repairAssessment } = job;
    const rowIndex = repairAssessment.findIndex((r) => r.id === rowId);

    if (rowIndex === -1) {
      throw new Error(`Row ${rowId} not found in job ${jobId}`);
    }

    if (repairAssessment[rowIndex].bubbleId) {
      await deleteRecord('bubble', repairAssessment[rowIndex].bubbleId);
    }

    repairAssessment.splice(rowIndex, 1);

    await updateRecord('job', { repairAssessment, _id: jobId });

    return body;
  },

  'DELETE job/:jobId/ra': async ({ jobId }) => {
    const job = await getRecord('job', jobId);
    const { repairAssessment } = job;

    job.repairAssessment = [];

    await updateRecord('job', { ...job, _id: jobId });

    return repairAssessment;
  },
};

const offlineGet = async (...args) => {
  const url = args[0];

  if (url === '/job') {
    const jobs = await getRecords('job');
    return { jobs };
  }

  const jobId = extractUrlSegment(url, 1); // Extracts the second segment (job ID)
  const subPath = extractUrlSegment(url, 2); // Extracts the third segment if available

  if (jobId) {
    if (subPath === 'ra') {
      const job = await getRecord('job', jobId);
      return job?.repairAssessment;
    }

    if (subPath === 'folders') {
      const tasks = await getRecordsUsingIndex('task', 'jobId', jobId);
      const folders = tasks.filter((task) => task.taskType === 'folder');

      return folders;
    }

    const jobIdClean = jobId.split('?')[0]; // Handle potential query params
    const job = await getRecord('job', jobIdClean);
    return job;
  }

  throw new Error(`Failed to handle ${url} with method GET`);
};

const offlinePut = async (url, body) => {
  const { handler, params, url: fullUrl } = matchRoute('PUT', url, handlers);
  return handler(params, body, fullUrl);
};

const offlinePost = async (url, body, ...args) => {
  const { handler, params, url: fullUrl } = matchRoute('POST', url, handlers);
  return handler(params, body, fullUrl, args);
};

const offlinePatch = async (...args) => {
  const url = args[0];
  if (new RegExp(`^/job/${uuidRegex}`).test(url)) {
    basePatch(url);
  }

  throw new Error(`Failed to handle ${url} with method PATCH`);
};

const offlineDelete = async (url) => {
  const { handler, params, url: fullUrl } = matchRoute('DELETE', url, handlers);
  return handler(params, {}, fullUrl);
};

export { offlineGet, offlinePost, offlinePut, offlinePatch, offlineDelete };
