import { v4 } from 'uuid';
import onlineService from './onlineService';

const uuid = v4;

const db = await new Promise((resolve) => {
  const request = indexedDB.open('dij', 1);
  request.onsuccess = (event) => {
    const innerdb = event.target.result;
    resolve(innerdb);
  };
  request.onupgradeneeded = (event) => {
    const innerdb = event.target.result;
    if (event.newVersion === 1) {
      // Initial setup

      // Expected record structure:
      // _id
      // recordType
      // recordId
      // status -> new, existing, deleted
      // isDirty
      // hasConflict
      // date

      // Status flow:
      // new -> existing clean
      // existing dirty -> existing clean
      // existing dirty -> existing conflict
      // existing conflict -> existing clean
      // deleted dirty -> deleted clean
      const syncObjectStore = innerdb.createObjectStore('sync', {
        keyPath: '_id',
      });

      syncObjectStore.createIndex('recordType', 'recordType', {
        unique: false,
      });
      syncObjectStore.createIndex('recordId', 'recordId', {
        unique: true,
      });
      syncObjectStore.createIndex('status', 'status', {
        unique: false,
      });
      syncObjectStore.createIndex('isDirty', 'isDirty', {
        unique: false,
      });
      syncObjectStore.createIndex('checkedJobId', 'checkedJobId', {
        unique: false,
      });
      syncObjectStore.createIndex('hasConflict', 'hasConflict', {
        unique: false,
      });

      innerdb.createObjectStore('session', {
        keyPath: '_id',
      });
      innerdb.createObjectStore('job', { keyPath: '_id' });

      const taskObjectStore = innerdb.createObjectStore('task', {
        keyPath: '_id',
      });
      taskObjectStore.createIndex('jobId', 'jobId', {
        unique: false,
      });
      taskObjectStore.createIndex('folderId', 'folderId', {
        unique: false,
      });

      const bubbleObjectStore = innerdb.createObjectStore('bubble', {
        keyPath: '_id',
      });
      bubbleObjectStore.createIndex('taskId', 'taskId', {
        unique: false,
      });

      const questionObjectStore = innerdb.createObjectStore('question', {
        keyPath: '_id',
      });
      questionObjectStore.createIndex('taskId', 'taskId', {
        unique: false,
      });

      innerdb.createObjectStore('user', {
        keyPath: '_id',
      });

      innerdb.createObjectStore('role', {
        keyPath: '_id',
      });

      innerdb.createObjectStore('site', {
        keyPath: '_id',
      });

      innerdb.createObjectStore('setting', {
        keyPath: '_id',
      });

      const imageObjectStore = innerdb.createObjectStore('image', {
        keyPath: '_id',
      });

      imageObjectStore.createIndex('recordId', 'recordId', {
        unique: false,
      });

      imageObjectStore.createIndex('recordType', 'recordType', {
        unique: false,
      });

      imageObjectStore.createIndex('path', 'path', {
        unique: false,
      });
    }
  };
});

async function createRecord(
  recordType,
  record,
  existsOnRemote = false,
  checkedJobId = null
) {
  try {
    const objectStoreNames = Array.from(db.objectStoreNames);
    if (!objectStoreNames.includes(recordType)) {
      console.warn(`Un-registered store: ${recordType}`);
      return null;
    }

    // Get record from offline database
    const recordStore = db
      .transaction(recordType, 'readwrite')
      .objectStore(recordType);

    if (!record._id) {
      record._id = uuid();
    }

    const recordToBeCreated = { _id: uuid(), ...record };

    const recordCreateRequest = new Promise((resolve, reject) => {
      const request = recordStore.put(recordToBeCreated);
      request.onsuccess = () => resolve(recordToBeCreated);
      request.onerror = (event) => reject(event.target.error);
    });

    // Create sync record
    const syncStore = db.transaction('sync', 'readwrite').objectStore('sync');
    const syncData = {
      _id: uuid(),
      recordType,
      recordId: recordToBeCreated._id,
      status: existsOnRemote ? 'existing' : 'new',
      isDirty: !existsOnRemote ? 'true' : 'false',
      hasConflict: false,
      date: new Date().getTime(),
    };
    if (checkedJobId) {
      syncData.checkedJobId = checkedJobId;
    }

    const syncCreateRequest = new Promise((resolve) => {
      const index = syncStore.index('recordId');
      const checkRequest = index.get(recordToBeCreated._id);

      checkRequest.onsuccess = () => {
        if (checkRequest.result) {
          console.warn(
            `[createRecord] Sync record already exists for recordId: ${recordToBeCreated._id}, type: ${recordType}`
          );
          resolve(null);
        } else {
          const addRequest = syncStore.add(syncData);
          addRequest.onsuccess = () => resolve(syncData);
          addRequest.onerror = (event) => {
            console.warn(
              '[createRecord] Failed to insert sync record',
              event.target.error
            );
            resolve(null);
          };
        }
      };

      checkRequest.onerror = () => {
        console.warn('[createRecord] Failed to check for existing sync record');
        resolve(null);
      };
    });

    // Wait for both record and sync record to be created
    return await Promise.all([recordCreateRequest, syncCreateRequest]).then(
      ([recordData]) => recordData
    );
  } catch (error) {
    console.error('[createRecord] Error occurred', error, {
      recordType,
    });

    // Extra debugging: Check if database and stores exist
    console.debug('[createRecord] Checking available object stores...');
    const objectStoreNames = Array.from(db.objectStoreNames);
    console.debug('[createRecord] Available object stores:', objectStoreNames);

    return null;
  }
}

function validateInputs(recordType, record) {
  if (!recordType || typeof recordType !== 'string') {
    throw new TypeError('Invalid recordType: must be a non-empty string');
  }
  if (!record || typeof record !== 'object' || !record._id) {
    throw new TypeError('Invalid record: must be an object with a valid _id');
  }
}

function getRequest(request, errorMessage) {
  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () =>
      reject(
        new Error(
          `${errorMessage}: ${request.error?.message || 'Unknown error'}`
        )
      );
  });
}

async function updateRecordStore(storeName, newData) {
  let tx;
  try {
    tx = db.transaction(storeName, 'readwrite');
  } catch (err) {
    throw new Error(
      `Failed to open transaction on store ${storeName}: ${err.message}`
    );
  }

  const store = tx.objectStore(storeName);
  const existing = await getRequest(
    store.get(newData._id),
    'Error retrieving record'
  );

  if (!existing) {
    throw new Error('Record not found');
  }

  // Remove keys not present in new data
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(existing)) {
    if (!(key in newData)) {
      delete existing[key];
    }
  }

  Object.assign(existing, newData);

  try {
    store.put(existing);
  } catch (err) {
    throw new Error(`Failed to update record in IndexedDB: ${err.message}`);
  }

  return existing;
}

async function updateSyncStore(
  recordId,
  { checkedJobId, deleteCheckedJobId, isDirty }
) {
  let tx;
  try {
    tx = db.transaction('sync', 'readwrite');
  } catch (err) {
    throw new Error(`Failed to open transaction on sync store: ${err.message}`);
  }

  const store = tx.objectStore('sync');
  const index = store.index('recordId');

  const syncRecord = await getRequest(
    index.get(recordId),
    'Error retrieving sync record'
  );

  if (!syncRecord) {
    throw new Error('Sync record not found');
  }

  if (checkedJobId) {
    syncRecord.checkedJobId = checkedJobId;
  }

  if (deleteCheckedJobId) {
    delete syncRecord.checkedJobId;
  }

  syncRecord.isDirty = isDirty;

  try {
    store.put(syncRecord);
  } catch (err) {
    throw new Error(
      `Failed to update sync record in IndexedDB: ${err.message}`
    );
  }

  return syncRecord;
}

async function updateRecord(
  recordType,
  record,
  checkedJobId = null,
  deleteCheckedJobId = false
) {
  validateInputs(recordType, record);

  const isOffline = !(await onlineService.getStatus());

  const updatedRecord = await updateRecordStore(recordType, record);
  const updatedSync = await updateSyncStore(record._id, {
    checkedJobId,
    deleteCheckedJobId,
    isDirty: isOffline ? 'true' : 'false',
  });

  return {
    success: true,
    record: updatedRecord,
    sync: updatedSync,
    isOffline,
  };
}

async function markSyncRecordForDeletion(recordId) {
  // Get sync record
  const syncStore = db.transaction('sync', 'readwrite').objectStore('sync');
  const syncIndex = syncStore.index('recordId');
  const syncStoreRequest = syncIndex.get(recordId);
  const syncUpdateRequest = new Promise((resolve) => {
    syncStoreRequest.onsuccess = (event) => {
      // Update sync record
      const data = event.target.result;
      data.status = 'deleted';
      data.isDirty = 'true';
      syncStore.put(data);
      resolve(data);
    };
  });

  const recordDeletionOutcome = await Promise.all([syncUpdateRequest]).then(
    () => true
  );

  return recordDeletionOutcome;
}

async function deleteRecord(recordType, recordId) {
  // Get record from offline database
  const recordStore = db
    .transaction(recordType, 'readwrite')
    .objectStore(recordType);
  const recordStoreRequest = recordStore.delete(recordId);
  const recordDeleteRequest = new Promise((resolve) => {
    recordStoreRequest.oncomplete = () => {
      resolve();
    };
  });

  const recordDeletionOutcome = await Promise.all([recordDeleteRequest]).then(
    () => true
  );

  return recordDeletionOutcome;
}

async function removeAttributesFromRecord(
  recordType,
  recordId,
  attributesToRemove
) {
  // Get record from offline database
  const recordStore = db
    .transaction(recordType, 'readwrite')
    .objectStore(recordType);
  const recordStoreRequest = recordStore.get(recordId);
  const recordUpdateRequest = new Promise((resolve) => {
    recordStoreRequest.onsuccess = (event) => {
      // Update offline record
      const data = event.target.result;
      attributesToRemove.forEach((attribute) => delete data[attribute]);
      recordStore.put(data);
      resolve(data);
    };
  });

  // Get sync record
  const syncStore = db.transaction('sync', 'readwrite').objectStore('sync');
  const syncIndex = syncStore.index('recordId');
  const syncStoreRequest = syncIndex.get(recordId);
  const syncUpdateRequest = new Promise((resolve) => {
    syncStoreRequest.onsuccess = (event) => {
      // Update sync record
      const data = event.target.result;
      data.isDirty = 'true';
      syncStore.put(data);
      resolve(data);
    };
  });

  const recordUpdateOutcome = await Promise.all([
    recordUpdateRequest,
    syncUpdateRequest,
  ]).then(([recordData]) => recordData);

  return recordUpdateOutcome;
}

async function getDirtySyncRecords(manualSync = false) {
  const tx = db.transaction('sync', 'readonly');
  const store = tx.objectStore('sync');
  // Make sure you actually created an index named 'isDirty' in your store definition
  const index = store.index('isDirty');

  return new Promise((resolve, reject) => {
    const dirtySyncRecords = [];
    const request = index.openCursor(IDBKeyRange.only('true')); // or "true" if stored as string

    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (manualSync) {
          dirtySyncRecords.push(cursor.value);
        } else if (!cursor.value.checkedJobId) {
          dirtySyncRecords.push(cursor.value);
        }

        cursor.continue();
      } else {
        resolve(dirtySyncRecords);
      }
    };

    request.onerror = (error) => {
      reject(error);
    };
  });
}

async function getDirtySyncRecordsByJobId(jobId) {
  if (!jobId || typeof jobId !== 'string') {
    throw new TypeError('Invalid jobId: must be a non-empty string');
  }

  const tx = db.transaction('sync', 'readonly');
  const store = tx.objectStore('sync');
  const index = store.index('isDirty');

  return new Promise((resolve, reject) => {
    const matchingRecords = [];
    const request = index.openCursor(IDBKeyRange.only('true'));

    request.onsuccess = (event) => {
      const cursor = event.target.result;

      if (cursor) {
        const record = cursor.value;
        if (record.checkedJobId === jobId) {
          matchingRecords.push(record);
        }
        cursor.continue();
      } else {
        resolve(matchingRecords);
      }
    };

    request.onerror = () => {
      reject(new Error(`Failed to retrieve dirty records for jobId: ${jobId}`));
    };
  });
}

function getRecordUsingSync(syncRecord) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(syncRecord.recordType, 'readonly');
    const objectStore = transaction.objectStore(syncRecord.recordType);
    const request = objectStore.get(syncRecord.recordId);

    request.onsuccess = (event) => {
      if (event.target.result !== undefined) {
        resolve(event.target.result);
      } else {
        console.warn(`No record found for ID: ${syncRecord.recordId}`);
        resolve(null); // Explicitly return null if record is not found
      }
    };

    request.onerror = (event) => {
      console.error(
        `Error fetching record (Type: ${syncRecord.recordType}, ID: ${syncRecord.recordId}):`,
        event.target.error
      );
      reject(event.target.error); // Reject the promise on error
    };
  });
}

async function getRecord(recordType, recordId) {
  try {
    const recordStore = db
      .transaction(recordType, 'readonly')
      .objectStore(recordType);
    const recordStoreRequest = recordStore.get(recordId);
    return new Promise((resolve) => {
      recordStoreRequest.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  } catch (e) {
    console.error(`Failed to getRecord for ${recordType}:${recordId}`);
  }
}

/**
 * Get all object from a table
 */
async function getRecords(recordType) {
  const recordStore = db
    .transaction(recordType, 'readonly')
    .objectStore(recordType);
  const recordStoreRequest = recordStore.getAll();
  return new Promise((resolve) => {
    recordStoreRequest.onsuccess = (event) => {
      const data = event.target.result;
      resolve(data);
    };
  });
}

/**
 * Query the index expecting multiple objects
 */
async function getRecordsUsingIndex(recordType, recordIndexName, query) {
  const recordStore = db
    .transaction(recordType, 'readonly')
    .objectStore(recordType);
  const recordIndex = recordStore.index(recordIndexName);
  const recordIndexRequest = recordIndex.openCursor(IDBKeyRange.only(query));
  return new Promise((resolve) => {
    const records = [];
    recordIndexRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        records.push(cursor.value);
        cursor.continue();
      } else {
        resolve(records);
      }
    };
  });
}

async function getSyncRecordUsingIndex(indexName, query) {
  const syncStore = db.transaction('sync', 'readonly').objectStore('sync');
  const syncIndex = syncStore.index(indexName);
  const syncIndexRequest = syncIndex.openCursor(IDBKeyRange.only(query));
  return new Promise((resolve) => {
    syncIndexRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        resolve(cursor.value);
      } else {
        resolve(null);
      }
    };
  });
}

async function removeCheckedJobIdFromSyncRecords(checkedJobId) {
  const syncStore = db.transaction('sync', 'readwrite').objectStore('sync');
  const syncIndex = syncStore.index('checkedJobId');
  const request = syncIndex.openCursor(IDBKeyRange.only(checkedJobId));

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const record = cursor.value;
        if (record.checkedJobId === checkedJobId) {
          delete record.checkedJobId;
          cursor.update(record);
        }
        cursor.continue();
      } else {
        resolve(true);
      }
    };

    request.onerror = (error) => {
      reject(error);
    };
  });
}

async function cacheRecord(recordType, record, jobId = null) {
  try {
    const blacklistedRecordTypes = ['jobsize'];
    if (blacklistedRecordTypes.includes(recordType)) {
      return;
    }

    // Check for checkedJobId and skip if already checked
    const syncRecord = await getSyncRecordUsingIndex('recordId', record._id);
    if (syncRecord && syncRecord.checkedJobId) {
      return null;
    }
  } catch (e) {
    console.error(`Failed to cacheRecord for ${recordType}:${record._id}`, {
      record,
    });
  }

  const existingRecord = await getRecord(recordType, record._id);
  if (existingRecord) {
    return updateRecord(recordType, record, jobId);
  }

  return createRecord(recordType, record, true, jobId);
}

export {
  db,
  cacheRecord,
  createRecord,
  updateRecord,
  deleteRecord,
  markSyncRecordForDeletion,
  removeAttributesFromRecord,
  getDirtySyncRecords,
  getDirtySyncRecordsByJobId,
  getRecord,
  getRecords,
  getRecordsUsingIndex,
  getRecordUsingSync,
  removeCheckedJobIdFromSyncRecords,
};
