import { getRecord } from '../services/db';
import {
  baseDelete,
  baseGet,
  basePatch,
  basePost,
  basePut,
  extractUrlSegment,
} from './base';

const offlineGet = async (...args) => {
  const url = args[0];
  const user = extractUrlSegment(url, 0);
  const id = extractUrlSegment(url, 1);
  if (user === 'users' && id === 'current') {
    return getRecord('session', 'user');
  }

  return baseGet(...args);
};

const offlinePost = async (...args) => {
  return basePost(...args);
};

const offlinePut = async (...args) => {
  return basePut(...args);
};

const offlinePatch = async (...args) => {
  return basePatch(...args);
};

const offlineDelete = async (...args) => {
  return baseDelete(...args);
};

export { offlineGet, offlinePost, offlinePut, offlinePatch, offlineDelete };
