<template>
  <tasks-template ref="tasks" tool="publisher"> </tasks-template>
</template>

<script>
import TaskTemplate from '../ToolsTemplates/TaskPage';
import store from '../../store/index';

export default {
  components: {
    'tasks-template': TaskTemplate,
  },

  async beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    try {
      if (!store.state.job || store.state.job.id !== id) {
        await store.dispatch('getJob', id);
      }
      next();
    } catch (error) {
      console.error('Failed to fetch job:', error);
      // Optionally redirect here yourself or just call next()
      next();
    }
  },
};
</script>

<style></style>
