import isOnline from 'is-online';

class OnlineService {
  constructor() {
    this.isOnline = navigator.onLine;
    this.lastChecked = 0;
    this.checkingPromise = null;
  }

  async checkOnlineStatus() {
    const now = Date.now();

    if (now - this.lastChecked >= 500) {
      this.lastChecked = now;
      this.checkingPromise = isOnline().then((status) => {
        this.isOnline = status;
        return status;
      });
    }

    return this.checkingPromise;
  }

  async getStatus() {
    if (!navigator.onLine) {
      return false;
    }

    return this.checkOnlineStatus();
  }
}

export default new OnlineService();
