export default {
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  data() {
    return {
      onUpdateCallback: {},
      onSaveCallback: {},
      onCancelCallback: {},
    };
  },
  methods: {
    handleKeyDown(event) {
      const { currentTask } = this.$store.state.TasksModule;
      if (
        event.key === 'Enter' &&
        this.onUpdateCallback.isUpdate &&
        currentTask &&
        currentTask.taskType
      ) {
        this.onUpdateCallback.callback();
      } else if (event.key === 'Enter' && this.onSaveCallback.isSave) {
        this.onSaveCallback.callback();
      } else if (event.key === 'Escape' && this.onCancelCallback.isCancel) {
        this.onCancelCallback.callback();
      }
    },
  },
};
