import { getRecords } from '../services/db';
import { baseDelete, baseGet, basePatch, basePost, basePut } from './base';

const offlineGet = async (...args) => {
  const url = args[0];
  if (/^\/sites/.test(url)) {
    const sites = await getRecords('site');

    return sites;
  }
  return baseGet(...args);
};

const offlinePost = async (...args) => {
  return basePost(...args);
};

const offlinePut = async (...args) => {
  return basePut(...args);
};

const offlinePatch = async (...args) => {
  return basePatch(...args);
};

const offlineDelete = async (...args) => {
  return baseDelete(...args);
};

export { offlineGet, offlinePost, offlinePut, offlinePatch, offlineDelete };
