import Axios from 'axios';
import { acquireToken } from '../services/auth';
import { createRecord } from '../services/db';

export default class DIJAPP {
  apiHost = 'http://localhost:3000';

  bucket = 'images';

  constructor() {
    if (window.location.href.includes('mosaic-dev')) {
      this.apiHost = 'https://api.dij.mosaic-dev.siemens-energy.cloud';
      this.bucket = 'sdo-sgt-dev-fra-di-jacket-dij-spa';
    } else if (window.location.href.includes('mosaic-qa')) {
      this.apiHost = 'https://api.dij.mosaic-qa.siemens-energy.cloud';
      this.bucket = 'sdo-sgt-qa-fra-di-jacket-dij-spa';
    } else if (window.location.href.includes('mosaic')) {
      this.apiHost = 'https://api.dij.mosaic.siemens-energy.cloud';
      this.bucket = 'sdo-sgt-prd-fra-di-jacket-dij-spa';
    } else if (window.location.href.includes('localhost')) {
      this.apiHost = 'http://localhost:3000';
      this.bucket = 'images';
    }
  }

  getToken() {
    return acquireToken();
  }

  /**
   *  Handle the proccess of sending images to S3 bucket
   * @param {Object} file
   * @param {String} fileName
   * @param {String} path
   */
  async uploadFileToS3(file, fileName, path, reportInfo) {
    const response = await this.getPresignedPostData(fileName, file.type, path);
    // eslint-disable-next-line no-useless-catch
    try {
      if (reportInfo) {
        await this.sendPresignedPostData(response, file, reportInfo);
      } else {
        await this.sendPresignedPostData(response, file, fileName);
      }
      return `/${path}/${fileName}`;
    } catch (error) {
      throw error;
    }
  }

  async offlineUploadFileToS3(file, fileName, path, recordType, recordId) {
    if (!file.type.startsWith('image/')) {
      throw new Error(
        'File type is not an image, only images can be uploaded while offline'
      );
    }

    const reader = new FileReader();
    const fileReadPromise = new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
    reader.readAsDataURL(file);
    try {
      const base64data = await fileReadPromise;
      await createRecord('image', {
        base64data,
        recordType,
        recordId,
        fileName,
        path: `/${path}/${fileName}`,
      });
      return `/${path}/${fileName}`;
    } catch (error) {
      console.error('Failed to cache image', error);
    }
  }

  async loadImageFromS3(imageUrl) {
    console.log(`Performing load image for ${imageUrl}`);

    let localBase = '';
    if (window.location.host.includes('localhost')) {
      localBase = 'http://localhost:4566/images';
    }

    let urlToGetImage = this.apiHost.replace('api.', '') + imageUrl;
    if (this.apiHost.includes('localhost')) {
      urlToGetImage = localBase + imageUrl;
    }

    const options = {
      method: 'GET',
      url: urlToGetImage,
    };

    try {
      console.log('Sending load request');
      const response = await Axios(options);

      return response.data;
    } catch (err) {
      console.error('failed to load image', err);
      return err;
    }
  }

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param selectedFile
   * @returns {Promise<any>}
   */
  async getPresignedPostData(name, type, path) {
    const data = JSON.stringify({
      name,
      type,
      path,
    });

    const token = await this.getToken();

    const options = {
      method: 'POST',
      headers: { authorization: token },
      data: JSON.parse(data),
      url: `${this.apiHost}/dij/image/presigned`,
    };
    const response = await Axios(options);

    const result = response.data;

    return result;
  }

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   * @param presignedPostData
   * @param file
   * @returns {Promise<any>}
   */
  async sendPresignedPostData(presignedPostData, file, fileName) {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    const newFile = new File([file], fileName);

    formData.append('file', newFile);

    const options = {
      method: 'POST',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
      url: presignedPostData.url,
    };

    try {
      await Axios(options);
    } catch (err) {
      console.error('failed to upload image', err);
      return err;
    }
  }
}
